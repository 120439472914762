.rb-subscription-success {
  .rb-result-img {

    margin-top: 0;

  }
  &-buttons {
    margin-top: 20px;

    .rb-btn {
      padding-left: 40px;
      padding-right: 40px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .rb-details-table {

    padding-bottom: 30px;
  }
  .rb-details-table__item__value, .rb-details-table__item__value a {
    min-width: auto;
  }
  .rb-details-table__dotted-space {
    margin-left: 9px;
  }
  .rb-container {
  width: 100%;
    margin-top: 30px;
    @media (min-width: $mobile_min) {
      max-width: 720px;
      padding: 0 40px 60px 40px;
    }
  }

  .rb-details-table__dotted-space {

    height: 12px;

  }
}
