.info-block {
  border: 1px solid #FFE400;
  border-radius: 2px;
  margin-bottom: 10px;
  &__img {
    background-color: #FFE400;
    padding-top: 16px;
    padding-bottom: 16.4px;
    padding-left: 13.5px;
    padding-right: 14.5px;
    img {
      width: 22px;
      @media (min-width: $mobile_min) {
        width: 32px;
      }
    }
  }
  &__text {
    padding-left: 14px;
    font-size: 12px;
    line-height: 15px;
    @media (min-width: $mobile_min) {
      padding-left: 22px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
