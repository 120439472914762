.static-page-content {
  background-color: #fff;
  max-width: 800px;
  margin-top: 40px;
  padding: 30px 20px;
  p, ul, ol {
    margin-bottom: 20px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }
}
