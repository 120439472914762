@font-face {
  font-family: "Proxima Nova";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Regular.otf");
}
@font-face {
  font-family: "Proxima Nova Light";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Light.otf");
}
@font-face {
  font-family: "Proxima Nova Light Italic";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Light_Italic.otf");
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Semibold.otf");
}
@font-face {
  font-family: "Proxima Nova Medium";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Medium.otf");
}
.rb-hidden {
  display: none !important;
}

.rb-column {
  width: 60%;
}

.rb-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rb-justify-center {
  justify-items: center;
}

.rb-self-center {
  justify-self: center;
}

.rb-self-stretch {
  justify-self: stretch;
}

.rb-text-center {
  text-align: center;
}

.rb-text-left {
  text-align: left;
}

.rb-mt-2xl {
  margin-top: 40px;
}

.rb-mt-normal {
  margin-top: 2rem;
}

.rb-mt-sm {
  margin-top: 0.75rem;
}

.rb-mt-xs {
  align-self: center;
  margin-top: 6px;
  font: normal normal normal 16px/20px "Proxima Nova", sans-serif;
}

.rb-position-absolute {
  position: absolute;
}

.rb-position-relative {
  position: relative;
}

.rb-display-flex {
  display: flex;
}

.rb-justify-content-space-between {
  justify-content: space-between;
}

.rb-justify-content-center {
  justify-content: center;
}

.rb-justify-content-flex-end {
  justify-content: flex-end;
}

.rb-justify-content-flex-start {
  justify-content: flex-start;
}

.rb-flex-wrap-wrap {
  flex-wrap: wrap;
}

.rb-flex-direction-column {
  flex-direction: column;
}

.rb-align-items-center {
  align-items: center;
}

.rb-align-items-flex-start {
  align-items: flex-start;
}

.rb-px-xl {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.rb-grow-0 {
  flex-grow: 0;
}

.w-100 {
  width: 100%;
}

.pr-20 {
  padding-right: 20px;
}

.mb-32 {
  margin-bottom: 32px;
}

.info-block {
  border: 1px solid #FFE400;
  border-radius: 2px;
  margin-bottom: 10px;
}
.info-block__img {
  background-color: #FFE400;
  padding-top: 16px;
  padding-bottom: 16.4px;
  padding-left: 13.5px;
  padding-right: 14.5px;
}
.info-block__img img {
  width: 22px;
}
@media (min-width: 640px) {
  .info-block__img img {
    width: 32px;
  }
}
.info-block__text {
  padding-left: 14px;
  font-size: 12px;
  line-height: 15px;
}
@media (min-width: 640px) {
  .info-block__text {
    padding-left: 22px;
    font-size: 16px;
    line-height: 20px;
  }
}

.rb-btn {
  display: flex;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  border-radius: 6px;
  cursor: pointer;
  color: #6f6f6f;
  background-color: #f8f9fa;
}
@media (min-width: 640px) {
  .rb-btn {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.rb-btn-svg {
  fill: #1a1818;
  margin-right: 8px;
}
.rb-btn-selected .rb-btn-svg {
  fill: #fff;
}
.rb-btn-file, .rb-btn-hash, .rb-btn-user-found {
  display: table-cell;
  vertical-align: middle;
  width: 160px;
}
@media (max-width: 639px) {
  .rb-btn-file, .rb-btn-hash, .rb-btn-user-found {
    width: 140px;
  }
}
.rb-btn-file {
  border-radius: 20px 1px 1px 21px;
}
.rb-btn-hash {
  border-radius: 1px 20px 21px 1px;
}
.rb-btn-selected {
  color: white;
  background-color: #1B2F28;
}
.rb-btn-click {
  background-color: #1B2F28;
  color: #fff;
  border-radius: 4px;
  width: 214px;
  height: 62px;
  justify-content: center;
}
@media (min-width: 640px) {
  .rb-btn-click {
    font-size: 1rem;
  }
}
.rb-btn-click-file {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  gap: 8px;
}
.rb-btn-click-file p {
  font-size: 14px;
  font-family: "Arial, Helvetica", sans-serif;
}
.rb-btn-click__file-hash {
  background: none;
  color: #1B2F28;
  border-radius: 0;
  width: auto;
  height: auto;
  text-decoration: underline;
}
.rb-btn-click__file-hash .rb-file-btn {
  padding: 0;
  gap: 0;
}
.rb-btn-verify {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  border-radius: 4px;
  max-width: 28.875rem;
  width: 140px;
  height: 42px;
  justify-content: flex-start;
  padding-left: 40px;
}
.rb-btn-verify--another {
  margin-top: 20px;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 285px;
}
@media (min-width: 640px) {
  .rb-btn-verify--another {
    height: 50px;
    margin-top: 33px;
    padding-top: 14px;
  }
}
.rb-btn-verify .rb-btn-click.rb-btn-verify {
  padding-right: 29px;
  padding-top: 10px;
}
.rb-btn-user {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  border-radius: 4px;
  max-width: 28.875rem;
  width: 210px;
  height: 42px;
  justify-content: flex-start;
  padding-left: 35px;
}
.rb-btn-user .rb-btn-arrow {
  right: 36px;
}
.rb-btn-stamp {
  width: 277px;
  height: 42px;
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 640px) {
  .rb-btn-stamp {
    width: 288px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}
.rb-btn-stamp-error {
  margin-bottom: 40px;
  padding-top: 16px;
  padding-bottom: 33px;
}
@media (max-width: 639px) {
  .rb-btn-stamp-small {
    padding-top: 21px;
  }
}
.rb-btn-disabled {
  cursor: default;
  background-color: #D0D0D0;
  color: #B1B1B1;
}
.rb-btn-disabled img {
  opacity: 0.2;
  mix-blend-mode: difference;
  filter: grayscale(100%);
}
.rb-btn-stamp-window {
  margin-right: auto;
  margin-left: auto;
  margin-top: 21px;
  width: 287px;
  height: 42px;
  padding-top: 11px;
  font-size: 16px;
}
@media (min-width: 640px) {
  .rb-btn-stamp-window {
    margin-top: 36px;
    width: 288px;
    height: 50px;
    padding-top: 16px;
    font-size: 18px;
  }
}
.rb-btn-arrow {
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 3px solid #FFFFFF;
  border-left: 3px solid #FFFFFF;
  transform: rotate(135deg);
  margin-left: 50px;
  margin-top: 8px;
}
.rb-btn-black {
  background-color: #1B3028;
  color: #fff;
  border: 1px solid #1B3028;
}
.rb-btn-black:hover, .rb-btn-black:active, .rb-btn-black:focus {
  background-color: #fff;
  color: #000;
}
.rb-btn-white {
  background-color: #fff;
  color: #000;
  border: 1px solid #1B3028;
}
.rb-btn-white:hover, .rb-btn-white:active, .rb-btn-white:focus {
  background-color: #1B3028;
  color: #fff;
}
.rb-btn-authentication {
  height: 50px;
  padding-top: 15px;
  margin-top: 40px;
  position: relative;
  width: 100%;
}
.rb-btn-authentication img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.rb-btn-google {
  height: 50px;
  padding-top: 14px;
  margin-top: 20px;
  width: 100%;
}
.rb-btn-google img {
  margin-right: 10px;
  margin-top: -5px;
  width: 25px;
}
.rb-btn-verified {
  margin-top: 20px;
  height: 42px;
}
.rb-btn-header-plans {
  margin-right: 30px;
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
}
@media (max-width: 639px) {
  .rb-btn-header-plans {
    margin-right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 28px;
    min-width: 102px;
  }
}

.landing-buttons {
  margin-top: auto;
}
.landing-buttons .button {
  margin-top: 29px;
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 29px;
  padding-right: 29px;
}
.landing-buttons .button svg {
  position: relative;
  margin-left: 12px;
  width: 7px;
}
.landing-buttons .button:hover {
  background-color: #000;
  color: #fff;
}
.landing-buttons .button:hover svg {
  fill: #fff;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rb-main-content-verify .rb-btn-file, .rb-main-content-verify .rb-btn-hash, .rb-main-content-verify .rb-btn-user-found, .rb-main-content-verify .rb-btn-long-csv {
  width: 140px;
}
@media (max-width: 639px) {
  .rb-main-content-verify .rb-btn-file, .rb-main-content-verify .rb-btn-hash, .rb-main-content-verify .rb-btn-user-found, .rb-main-content-verify .rb-btn-long-csv {
    width: 111px;
  }
}
.rb-main-content-verify .rb-btn-long-csv {
  border-radius: 1px 20px 21px 1px;
  justify-content: center;
}
.rb-main-content-verify .rb-btn-file {
  border-right: 1px solid #6f6f6f;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rb-main-content-verify .rb-btn-hash, .rb-main-content-verify .rb-btn-user-found {
  border-radius: 0;
  border-right: 1px solid #6f6f6f;
}

.rb-input {
  border: 1px solid rgba(112, 112, 112, 0.5019607843);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  max-width: 28.875rem;
  width: 350px;
  height: 42px;
}
.rb-input:placeholder-shown {
  font-style: italic;
}
.rb-input.invalid {
  border: 1px solid red;
}
.rb-input-wrap {
  margin-bottom: 20px;
  position: relative;
}
.rb-input-wrap .btn-show-hide {
  position: absolute;
  padding-right: 12px;
  top: 25px;
  border-left: 1px solid rgba(112, 112, 112, 0.5019607843);
  padding-left: 12px;
  height: 40px;
  right: 0;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rb-input-wrap .btn-show-hide i {
  top: 2px;
  position: relative;
}
.rb-input-wrap .btn-show-hide:hover {
  background-color: #1B3028;
}
.rb-input-wrap .btn-show-hide:hover i {
  color: #fff;
}
.rb-input-wrap-key input {
  padding-right: 50px;
}
.rb-input-label {
  display: block;
  line-height: 19px;
  margin-bottom: 5px;
}
.rb-input-error {
  border-color: red;
}
.rb-input-error-text {
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 14px;
}
.rb-input-error-list {
  list-style-type: none;
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
.rb-input-error-list li {
  margin-bottom: 10px;
}

.rb-input-wrap-key .rb-input {
  width: 325px;
}
@media (max-width: 767px) {
  .rb-input-wrap-key .rb-input {
    width: calc(100% - 25px);
  }
}
.rb-input-wrap-key .btn-show-hide {
  right: 25px;
}
.rb-input-wrap-key img {
  position: absolute;
  top: calc(50% + 3.5px);
  right: 0;
  cursor: pointer;
}

/* Custom radio button */
.rb-radio-button {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 20px;
  user-select: none;
  margin-bottom: 20px;
}
.rb-radio-button input[type=radio] {
  display: none;
}
.rb-radio-button label {
  cursor: pointer;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}
.rb-radio-button label sup {
  position: absolute;
  right: -30px;
  font-size: 14px;
  top: -47px;
  background-color: #C6E870;
  padding: 4px 9px;
  color: #000;
}
.rb-radio-button label sup:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  bottom: -7px;
  transform: rotate(180deg);
  width: 0;
  border-bottom: 7px solid #c6e970;
  left: 16px;
}

.rb-body {
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100svh;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Arial, Helvetica", sans-serif;
}

.rb-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 150px;
  color: black;
  background: #EDEDED 0% 0% no-repeat padding-box;
  position: relative;
}
@media (min-width: 640px) {
  .rb-main-content {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .rb-main-content {
    padding-bottom: 100px;
  }
}
@media (max-height: 1010px) {
  .rb-main-content {
    justify-content: center;
  }
}

.rb-text-normal {
  font: normal normal normal 14px/17px "Proxima Nova Light", sans-serif;
}

.rb-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  height: 100%;
}

.full-screen-min-height {
  min-height: 100svh;
}

.rb-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (min-width: 640px) {
  .rb-screen {
    padding-top: 0;
  }
}
.rb-screen .rb-area {
  border: 1px dashed rgba(112, 112, 112, 0.5019607843);
}

.rb-header {
  font: normal normal normal 26px/31px "Arial, Helvetica", sans-serif;
  text-align: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 400;
}
@media (min-width: 640px) {
  .rb-header {
    font: normal normal normal 46px/54px "Arial, Helvetica", sans-serif;
    margin-bottom: 12px;
    margin-top: 102px;
  }
}
.rb-header-wrap {
  max-width: 1004px;
  width: 100%;
  text-align: center;
  position: relative;
}
.rb-header-stamp {
  margin-top: 0;
}

.rb-subheader {
  font: normal normal normal 16px/20px "Proxima Nova", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 308px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) {
  .rb-subheader {
    font: normal normal normal 18px/22px "Proxima Nova", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 508px;
    height: 22px;
  }
}

.rb-method-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.rb-btns-select {
  display: flex;
  flex-grow: 0;
  padding: 0.375rem;
  align-self: center;
  border-radius: 12px;
  position: relative;
}
.rb-btns-select-disabled button {
  opacity: 0.5;
  cursor: default;
}

.rb-method-file__screen, .rb-method-hash__screen, .rb-method-csv__screen {
  width: 100%;
}

@media (min-width: 640px) {
  .rb-method-hash__screen .hash-screen-container p {
    padding-top: 73px;
  }
}

.rb-method {
  align-content: center;
  padding-top: 28px;
  width: 100%;
  box-sizing: unset;
}

.rb-area {
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 13.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  background-color: #f8f9fa;
  transition: transform 135ms ease-out;
  margin: auto;
  width: calc(100% - 40px);
  max-width: 390px;
  height: 278px;
}
@media (min-width: 640px) {
  .rb-area {
    max-width: 964px;
    height: 284px;
    border: 1px dashed rgba(112, 112, 112, 0.5019607843);
  }
}
.rb-area-disabled {
  background-color: rgba(255, 255, 255, 0.5);
}
.rb-area__text {
  display: none;
}
@media (min-width: 640px) {
  .rb-area__text {
    display: block;
    font: normal normal 300 16px/20px "Proxima Nova Light", sans-serif;
    color: #707070;
  }
}
.rb-area-no-hash {
  gap: 2em;
}

.rb-area.dragging {
  transform: scale(1.05);
}

.rb-container {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) {
  .rb-container {
    width: calc(100% - 40px);
    max-width: 964px;
  }
}

@media (max-width: 639px) {
  .rb-hash-text-wrap {
    background-color: #fff;
    padding-left: 28px;
    padding-right: 27px;
    padding-top: 39px;
    padding-bottom: 20px;
  }
}

.rb-hash-input {
  position: relative;
}
.rb-hash-input-verify {
  width: 100%;
  max-width: 335px;
}
.rb-hash-input-verify p {
  text-align: left;
}
.rb-hash-input-verify {
  max-width: 350px;
}
.rb-hash-input input {
  width: 100%;
  max-width: 100%;
  cursor: default;
}
.rb-hash-input .rb-btn-disabled {
  background-color: rgba(255, 255, 255, 0.3);
}
.rb-hash-input img {
  position: absolute;
  right: 10px;
  top: calc(50% - 11px);
  cursor: pointer;
}

.rb-method-hash-text {
  font-size: 16px;
  line-height: 20px;
}
.rb-method-hash-text a {
  text-decoration: underline;
  color: #000;
}
.rb-method-hash-text a:hover {
  text-decoration: none;
}
.rb-method-hash-text-wrap {
  margin-top: 30px;
  justify-content: center;
}
@media (max-width: 639px) {
  .rb-method-hash-text-wrap {
    align-items: flex-start;
    max-width: 285px;
    margin-left: auto;
    margin-right: auto;
  }
}
.rb-method-hash-text-wrap img {
  margin-right: 7.5px;
  opacity: 0;
}
@media (min-width: 640px) {
  .rb-method-hash-text-wrap img {
    display: none;
  }
}
.rb-method-hash-text-wrap-first {
  margin-bottom: 5px;
}
.rb-method-hash-text-wrap-first img {
  opacity: 1;
}
@media (min-width: 640px) {
  .rb-method-hash-text-wrap-first img {
    display: block;
  }
}
.rb-method-hash-text-wrap-last {
  margin-bottom: 12px;
}

.stamp-bulk__text {
  border: 1px solid #C6E970;
  background-color: #EDF0E5;
  font-family: "Proxima Nova Light", sans-serif;
  margin-top: 31px;
  width: 100%;
  margin-bottom: -28px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  padding-top: 13px;
  padding-bottom: 12px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: calc(100% - 40px);
}
@media (min-width: 640px) {
  .stamp-bulk__text {
    width: auto;
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 22px;
    padding: 10px 34px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 639px) {
  .stamp-bulk__text {
    font-family: "Proxima Nova Semibold", sans-serif;
  }
}
.stamp-bulk__text a {
  text-decoration: underline;
}
@media (max-width: 639px) {
  .stamp-bulk__text a {
    display: block;
    margin-top: 5px;
    font-family: "Proxima Nova Light", sans-serif;
  }
}
.stamp-bulk__text a:hover {
  text-decoration: none;
}

.hash-screen-container {
  padding: 0 20px 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 13.5rem;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2px;
  background-color: #f8f9fa;
  transition: transform 135ms ease-out;
  margin: auto;
  height: 278px;
  border: 1px dashed rgba(112, 112, 112, 0.5019607843);
}
.hash-screen-container p {
  padding-top: 39px;
  padding-bottom: 5px;
}
.hash-screen-container p a {
  text-decoration: underline;
}
.hash-screen-container p a:hover {
  text-decoration: none;
}

.csv-screen-container {
  height: auto;
}

.rb-info {
  padding: 28px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  justify-content: center;
  font-size: 16px;
  max-width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.rb-info button {
  margin-bottom: 10px;
}
.rb-info span {
  color: #333333;
  font-family: "Proxima Nova Light", sans-serif;
}
.rb-info a {
  text-decoration: underline;
  margin-right: 5px;
}
.rb-info a:hover {
  text-decoration: none;
}
.rb-info img {
  margin-right: 10px;
}
.rb-info .rb-stamper-login {
  font-size: 14px;
}

.rb-hash {
  bottom: 13px;
  left: 15px;
  font-size: 16px;
  color: #707070;
  font-family: "Proxima Nova Light", sans-serif;
  padding-right: 30px;
}
.rb-hash__text {
  white-space: nowrap;
  padding-right: 0.3rem;
}
.rb-hash__content {
  word-break: break-all;
  text-align: left;
}
.rb-hash__content img {
  margin-left: 7px;
  margin-top: -2px;
}

.rb-file-loaded {
  margin-bottom: 20px;
}
.rb-file-loaded span {
  font-size: 18px;
  padding-left: 10px;
}

.rb-invalid-text {
  margin-top: 5px;
  position: absolute;
  color: red;
}

.rb-stamp-found, .rb-stamp-created, .rb-stamp-window {
  flex-grow: 0;
  margin-top: 26px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1607843137);
  max-width: 692px;
  background-color: white;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 59px;
  position: relative;
}
@media (min-width: 640px) {
  .rb-stamp-found, .rb-stamp-created, .rb-stamp-window {
    padding-top: 53px;
  }
}
@media (min-width: 640px) {
  .rb-stamp-found .rb-header, .rb-stamp-created .rb-header, .rb-stamp-window .rb-header {
    margin-top: 3px;
    margin-bottom: 0;
  }
}
@media (min-width: 640px) {
  .rb-stamp-found .rb-details-table, .rb-stamp-created .rb-details-table, .rb-stamp-window .rb-details-table {
    margin-top: 0;
  }
}
@media (min-width: 640px) {
  .rb-stamp-found .rb-btn-verify.rb-btn-verify--another, .rb-stamp-created .rb-btn-verify.rb-btn-verify--another, .rb-stamp-window .rb-btn-verify.rb-btn-verify--another {
    margin-top: 33px;
  }
}
.rb-stamp-found .scalloped-top, .rb-stamp-found .scalloped-bottom, .rb-stamp-created .scalloped-top, .rb-stamp-created .scalloped-bottom, .rb-stamp-window .scalloped-top, .rb-stamp-window .scalloped-bottom {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.14) 40%, #E4E4E4 50%, transparent 0%);
  background-size: 35.8px 30px;
  background-repeat: repeat-x;
  width: 100%;
  height: 31px;
  left: 6px;
  max-width: calc(100% - 16px);
}
.rb-stamp-found .scalloped-left, .rb-stamp-found .scalloped-right, .rb-stamp-created .scalloped-left, .rb-stamp-created .scalloped-right, .rb-stamp-window .scalloped-left, .rb-stamp-window .scalloped-right {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.14) 40%, #E4E4E4 50%, transparent 0%);
  background-size: 30px 31.5px;
  background-repeat: repeat-y;
  width: 31px;
  height: 100%;
  top: 10px;
  max-height: calc(100% - 21px);
}
.rb-stamp-found .scalloped-top, .rb-stamp-created .scalloped-top, .rb-stamp-window .scalloped-top {
  top: -16px;
}
.rb-stamp-found .scalloped-bottom, .rb-stamp-created .scalloped-bottom, .rb-stamp-window .scalloped-bottom {
  bottom: -16px;
}
.rb-stamp-found .scalloped-left, .rb-stamp-created .scalloped-left, .rb-stamp-window .scalloped-left {
  left: -16px;
}
.rb-stamp-found .scalloped-right, .rb-stamp-created .scalloped-right, .rb-stamp-window .scalloped-right {
  right: -16px;
}

.rb-information-link {
  color: #1B2F28;
  text-decoration: underline;
  font: normal normal normal 16px/20px "Proxima Nova Light", sans-serif;
  display: block;
  margin-top: 30px;
}

.rb-result-img {
  width: 64px;
  height: 64px;
  margin-top: 102px;
  margin-bottom: 10px;
}
@media (min-width: 640px) {
  .rb-result-img {
    margin-bottom: 13px;
  }
}
@media (max-height: 1010px) {
  .rb-result-img {
    margin-top: 28px;
  }
}

.rb-account-info {
  font-size: 14px;
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  .rb-account-info {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.rb-account-info-key {
  font-family: "Proxima Nova Light", sans-serif;
  padding-bottom: 10px;
  line-height: 20px;
  font-size: 16px;
  color: #707070;
}
.rb-account-info-key__time {
  padding-bottom: 0;
}
.rb-account-info-timedelta {
  border-radius: 8px;
  background-color: #EDF0E5;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}
@media (max-width: 639px) {
  .rb-account-info-timedelta {
    display: none;
  }
}
.rb-account-info img {
  margin-bottom: 5px;
}
.rb-account-info-found__user {
  font-size: 22px;
  line-height: 26px;
  font-family: "Proxima Nova Medium", sans-serif;
  color: #000;
  margin-bottom: 25px;
  word-wrap: break-word;
  max-width: 100%;
}
@media (min-width: 640px) {
  .rb-account-info-found__user {
    margin-right: 35px;
    margin-bottom: 0;
    max-width: 50%;
  }
}
.rb-account-info-status {
  font-size: 22px;
  line-height: 26px;
  font-family: "Proxima Nova Medium", sans-serif;
}
@media (min-width: 640px) {
  .rb-account-info-status {
    margin-left: 35px;
  }
}
.rb-account-info-status img {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}
.rb-account-info-found__time {
  font-family: "Proxima Nova", sans-serif;
  position: relative;
}
@media (max-width: 639px) {
  .rb-account-info-found__time {
    white-space: nowrap;
  }
}
.rb-account-info-found__time .help__question.top-right {
  top: -29px;
  right: 30px;
}
.rb-account-info-collection {
  font-family: "Proxima Nova", sans-serif;
  position: relative;
  margin-top: 25px;
  margin-bottom: 0;
  padding-right: 60px;
}
@media (max-width: 639px) {
  .rb-account-info-collection {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 640px) {
  .rb-account-info-collection-wrap {
    margin-top: 25px;
  }
}
@media (max-width: 639px) {
  .rb-account-info-wrap {
    display: block;
  }
}

@media (max-width: 639px) {
  .rb-error-header {
    margin-bottom: 36px;
  }
}
.rb-error-header span {
  display: none;
}
@media (min-width: 640px) {
  .rb-error-header span {
    display: inline-block;
  }
}
.rb-error-text {
  font-size: 16px;
  line-height: 20px;
  padding-top: 48px;
  padding-bottom: 37px;
  text-align: center;
  word-break: break-all;
}
@media (max-width: 639px) {
  .rb-error-text {
    padding-top: 0;
    padding-bottom: 87px;
  }
}
@media (max-width: 639px) {
  .rb-error-text-wrap {
    background-color: #fff;
    padding: 17px;
    text-align: center;
    width: 100%;
    min-height: 278px;
  }
}
.rb-error-text-contact {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 33px;
  text-align: center;
}
@media (max-width: 639px) {
  .rb-error-text-contact {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
}
.rb-error-text-contact a {
  text-decoration: underline;
}
.rb-error-timestamp {
  font-size: 12px;
  position: absolute;
  bottom: 23px;
  font-family: "Proxima Nova Light", sans-serif;
}

.rb-details-table {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  min-height: 278px;
  background-color: #fff;
  max-width: 100%;
}
@media (min-width: 640px) {
  .rb-details-table {
    padding-left: 21px;
    padding-right: 21px;
    width: 580px;
    border: none !important;
    min-height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-top: 2rem;
  }
}
.rb-details-table__item {
  display: flex;
  font: normal normal normal 16px/20px "Proxima Nova Light", sans-serif;
  color: #707070;
}
.rb-details-table__item__header {
  margin-bottom: 7px;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 14px;
  line-height: 17px;
}
.rb-details-table__item__header .download-pdf {
  cursor: pointer;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin-top: 10px;
  text-decoration: underline;
}
.rb-details-table__item__header .download-pdf:hover {
  text-decoration: none;
}
.rb-details-table__item__header a {
  color: #707070;
  text-decoration: underline;
  font-size: 14px;
  line-height: 17px;
}
.rb-details-table__item__header a:hover {
  text-decoration: none;
}
.rb-details-table__item__header img {
  width: 15px;
  height: 15px;
}
.rb-details-table__item__value, .rb-details-table__item__value a {
  margin-left: auto;
  color: #6f6f6f;
  font: normal normal normal 14px/17px "Proxima Nova Light", sans-serif;
  min-width: 114px;
}
.rb-details-table__item__value {
  text-align: right;
}
.rb-details-table__item__value a {
  text-decoration: underline;
}
.rb-details-table__item__value a:hover {
  text-decoration: none;
}
.rb-details-table__item img {
  margin-left: 10px;
  width: 15px;
  height: 15px;
}
.rb-details-table__item img.clicked {
  animation: animate_copy 300ms cubic-bezier(0, 0, 0.2, 1);
}
.rb-details-table__header {
  font: normal normal normal 16px/20px "Proxima Nova Semibold", sans-serif;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #707070;
}
@media (min-width: 640px) {
  .rb-details-table__header {
    margin-top: 48px;
  }
}
.rb-details-table__header span {
  position: relative;
}
.rb-details-table__header span .help__question-answer {
  right: -256px;
  left: auto;
  top: -16px;
}
.rb-details-table__header span .help__question-answer:before {
  left: -9px;
  top: 25px;
  transform: rotate(-90deg);
}
.rb-details-table__header span .help__question-answer:after {
  left: -7px;
  top: 25px;
  transform: rotate(-90deg);
}
.rb-details-table__copy {
  cursor: pointer;
}
.rb-details-table__dotted-space {
  margin-left: 18px;
  margin-right: 9px;
  height: 50%;
  flex-grow: 1;
  background-image: linear-gradient(to right, #a2a2a2 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
.rb-details-table__dotted-space span {
  background-color: #fff;
  padding-right: 6px;
  font: normal normal normal 14px/17px "Proxima Nova Light", sans-serif, sans-serif;
  top: -5px;
  position: relative;
}
.rb-details-table-not-found-text {
  margin-top: 20px;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #707070;
}

@keyframes animate_copy {
  100% {
    transform: scale(1.15);
  }
}
.pagination-controls-item {
  padding: 2px 15px 0px 15px;
  font-size: 14px;
  line-height: 28px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-right: none;
  cursor: pointer;
}
@media (max-width: 767px) {
  .pagination-controls-item {
    padding: 0 8px 0 8px;
  }
}
@media (max-width: 767px) {
  .pagination-controls-item:first-child {
    display: none;
  }
}
.pagination-controls-item:last-child {
  border-right: 1px solid rgba(112, 112, 112, 0.2);
}
@media (max-width: 767px) {
  .pagination-controls-item:last-child {
    display: none;
  }
}
@media (max-width: 767px) {
  .pagination-controls-item:nth-last-child(2) {
    border-right: 1px solid rgba(112, 112, 112, 0.2);
  }
}
.pagination-controls-disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.3);
}
.pagination-controls-active {
  cursor: default;
  background-color: #EDF0E5;
}

.html-table {
  min-width: 815px;
  margin-right: 10px;
  margin-left: 10px;
}
.html-table table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.html-table table tr th {
  padding-bottom: 7px;
  background-color: #EDF0E5;
  padding-top: 9px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.html-table table tr th:first-child {
  padding-left: 15px;
}
.html-table table tr td {
  padding-top: 11px;
  padding-bottom: 10px;
}
.html-table table tr td:first-child {
  padding-left: 15px;
}
.html-table table tr td a {
  text-decoration: underline;
}
.html-table table tr td a:hover {
  text-decoration: none;
}
.html-table table tr td button {
  cursor: pointer;
}
.html-table table tr:nth-child(even) td {
  background-color: rgba(237, 240, 229, 0.4);
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  border-top: 1px solid rgba(112, 112, 112, 0.3);
}
.html-table-wrap {
  overflow-y: auto;
  margin-bottom: 28px;
}
.html-table-wrap .no-data {
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 130px;
  align-items: flex-end;
  font-family: "Proxima Nova Light", sans-serif;
}
.html-table-copy {
  cursor: pointer;
  margin-left: 5px;
}
.html-table-custom {
  min-width: auto;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0.875em;
}
.html-table-custom-wrap > div {
  overflow-y: hidden;
}
.html-table-custom-wrap table tr th:last-child .m_8bffd616 {
  justify-content: center;
}
.html-table-custom-wrap-tolerance table tr th:last-child .m_8bffd616 {
  justify-content: flex-start;
}
.html-table-custom-wrap-tolerance table tr:nth-child(even) {
  background-color: #F9FAF7;
}
.html-table-custom .mantine-ActionIcon-root {
  background: none;
  top: -2px;
}
.html-table-custom .mantine-ActionIcon-root:hover {
  background: none;
}
.html-table-custom .mantine-ActionIcon-root svg {
  stroke: rgba(112, 112, 112, 0.4);
}
.html-table-custom-tolerance-new tr th .mrt-table-head-cell-content {
  justify-content: center !important;
}

.rb-loading__loader {
  position: relative;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
}
.rb-loading__loader span {
  display: block;
  width: 5rem;
  height: 5rem;
  border: 0.75rem solid #e9e9e9;
  border-top: 0.75rem solid #000;
  border-radius: 50%;
  position: absolute;
}
.rb-loading__title {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
@media (min-width: 640px) {
  .rb-loading__title {
    font-size: 22px;
  }
}
.rb-loading__text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  font-family: "Proxima Nova Light", sans-serif;
}
@media (min-width: 640px) {
  .rb-loading__text {
    font-size: 15px;
  }
}
.rb-loading-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.rb-loading-wrap {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  max-width: 700px;
  margin-top: 5rem;
  max-height: 520px;
  min-height: 200px;
}
.rb-loading-wrap:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
@media (min-width: 640px) {
  .rb-loading-wrap:before {
    content: none;
  }
}

.rb-notification-block {
  background-color: #EDF0E5;
  color: #000;
  border: 1px solid rgba(112, 112, 112, 0.5019607843);
  text-align: center;
  padding: 10px 20px;
  position: absolute;
  top: -20px;
  width: 80%;
  left: 10%;
  border-radius: 4px;
  -webkit-box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);
  -moz-box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);
  box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);
}
.rb-notification-text p {
  font-size: 15px;
  padding-top: 5px;
}
@media (min-width: 640px) {
  .rb-notification-text p {
    font-size: 19px;
  }
}
.rb-notification-buttons {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: 250px;
  margin-bottom: 5px;
}
.rb-notification-buttons-item {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (min-width: 640px) {
  .rb-notification-buttons-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.rb-hash-input .rb-notification-block:before, .rb-hash-input .rb-notification-block:after {
  top: 3px;
}

.static-page-content {
  background-color: #fff;
  max-width: 800px;
  margin-top: 40px;
  padding: 30px 20px;
}
.static-page-content p, .static-page-content ul, .static-page-content ol {
  margin-bottom: 20px;
}
.static-page-content h1, .static-page-content h2, .static-page-content h3, .static-page-content h4, .static-page-content h5, .static-page-content h6 {
  margin-bottom: 15px;
}

.help__trigger {
  position: fixed;
  right: 10px;
  bottom: 8px;
  width: 107px;
  height: 89px;
  transition: bottom 0.5s ease;
}
@media (min-width: 640px) {
  .help__trigger {
    bottom: 8px;
  }
}
@media (max-width: 639px) {
  .help__trigger {
    display: none;
  }
}
.help__trigger-text {
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 7px;
  font-family: "Proxima Nova Light", sans-serif;
}
.help__trigger-text span {
  font-family: "Proxima Nova Medium", sans-serif;
}
.help__trigger svg rect {
  transform: translate(1377px, 1049px);
  fill: #dedede;
}
.help__video {
  position: absolute;
  right: 0;
  top: 102px;
  margin-right: 20px;
  border: 1px solid #C6E870;
  padding: 6px;
  text-align: left;
  width: 36px;
  height: 36px;
  transition: width 0.5s ease, height 0.5s ease, padding 0.5s ease, background-color 0.5s ease;
}
@media (max-width: 730px) {
  .help__video {
    top: 159px;
    margin-right: 10px;
  }
}
@media (max-width: 639px) {
  .help__video {
    display: none;
  }
}
.help__video-icon {
  cursor: pointer;
  opacity: 0.6;
  width: 23px;
  height: 23px;
  transition: width 0.5s ease, height 0.5s ease;
}
.help__video-close {
  opacity: 0;
  position: absolute;
  z-index: -1;
  right: 9px;
  top: 8px;
  cursor: pointer;
  transition: z-index 0.5s ease, opacity 0.5s ease;
}
.help__video-text {
  opacity: 0;
  white-space: nowrap;
  padding-top: 10px;
  overflow: hidden;
  width: 0%;
}
.help__video-link {
  opacity: 0;
  white-space: nowrap;
  width: 0%;
  display: block;
  overflow: hidden;
}
.help__video.active {
  background-color: #EDF0E5;
  width: 215px;
  height: 109px;
  padding: 16px 9px 12px 16px;
}
@media (max-width: 920px) {
  .help__video.active {
    width: 115px;
    height: 128px;
  }
}
.help__video.active:before {
  content: "";
  position: absolute;
  top: 40%;
  left: -13px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 11px solid #C6E870;
  transform: rotate(-90deg);
}
.help__video.active:after {
  content: "";
  position: absolute;
  top: 40%;
  left: -11px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 11px solid #EDF0E5;
  transform: rotate(-90deg);
}
.help__video.active .help__video-icon {
  width: 31px;
  height: 31px;
  cursor: default;
  opacity: 1;
}
.help__video.active .help__video-close {
  opacity: 1;
  z-index: 0;
}
.help__video.active .help__video-text {
  opacity: 1;
  width: 100%;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  font-family: "Proxima Nova Light", sans-serif;
}
.help__video.active .help__video-link {
  opacity: 1;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-family: "Proxima Nova Light", sans-serif;
  color: #000;
}
@media (max-width: 920px) {
  .help__video.active .help__video-link {
    display: none;
  }
}
.help__video.active .help__video-link a {
  text-decoration: underline;
  padding-right: 5px;
  padding-left: 5px;
}
.help__video.active .help__video-link a:hover {
  text-decoration: none;
}
.help__video.active .help__video-link-small {
  display: none;
  text-decoration: underline;
}
@media (max-width: 920px) {
  .help__video.active .help__video-link-small {
    display: block;
  }
}
.help__video.active .help__video-link-small:hover {
  text-decoration: none;
}
.help__question {
  position: absolute;
  right: -32px;
  top: calc(50% - 12px);
}
@media (max-width: 639px) {
  .help__question {
    display: none;
  }
}
.help__question-mark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(112, 112, 112, 0.2);
  background-color: #fff;
  font-size: 16px;
  line-height: 15px;
  font-family: "Proxima Nova Medium", sans-serif;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.help__question-mark span {
  position: relative;
  top: 1px;
  color: #1B2F28;
}
.help__question-answer {
  background-color: #fff;
  width: 250px;
  padding: 8px 10px 9px 8px;
  position: absolute;
  right: -256px;
  top: 2px;
  border: 1px solid #C6E970;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s, z-index 0.5s ease;
  text-align: left;
}
.help__question-answer:before {
  content: "";
  position: absolute;
  top: 5%;
  left: -9px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #C6E970;
  transform: rotate(-90deg);
}
.help__question-answer:after {
  content: "";
  position: absolute;
  top: 5%;
  left: -7px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #fff;
  transform: rotate(-90deg);
}
.help__question-answer p, .help__question-answer a {
  font-size: 14px;
  line-height: 15px;
  font-family: "Proxima Nova Medium", sans-serif;
}
.help__question-answer p {
  padding-bottom: 4px;
}
.help__question-answer a {
  color: #707070;
  border-bottom: 1px solid #000;
}
.help__question-answer a:hover {
  border: none;
}
@media (max-width: 940px) {
  .help__question-answer {
    left: -256px;
    right: auto;
  }
  .help__question-answer:before {
    left: auto;
    right: -9px;
    transform: rotate(90deg);
  }
  .help__question-answer:after {
    left: auto;
    right: -7px;
    transform: rotate(90deg);
  }
}
.help__question.top-right {
  top: -33px;
  right: 0;
}
.help__question.top-right .help__question-answer {
  left: -256px;
  right: auto;
}
.help__question.top-right .help__question-answer:before {
  left: auto;
  right: -9px;
  transform: rotate(90deg);
}
.help__question.top-right .help__question-answer:after {
  left: auto;
  right: -7px;
  transform: rotate(90deg);
}
.help__question.active .help__question-mark {
  border-color: #fff;
  background-color: #CEE88C;
}
.help__question.active .help__question-mark span {
  position: relative;
  top: 1px;
  color: #fff;
}
.help__question.active .help__question-answer {
  z-index: 10;
  opacity: 1;
}

@media (max-width: 767px) {
  .rb-info:not(.rb-flex-wrap-wrap) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 639px) {
  .rb-info:not(.rb-flex-wrap-wrap) {
    padding-left: 28px;
    padding-right: 28px;
  }
}
.rb-info:not(.rb-flex-wrap-wrap) .help__question {
  right: -5px;
}
@media (max-width: 767px) {
  .rb-info:not(.rb-flex-wrap-wrap) .help__question {
    right: 20px;
  }
}
@media (max-width: 1250px) {
  .rb-info:not(.rb-flex-wrap-wrap) .help__question .help__question-answer {
    left: -256px;
    right: auto;
  }
  .rb-info:not(.rb-flex-wrap-wrap) .help__question .help__question-answer:before {
    left: auto;
    right: -9px;
    transform: rotate(90deg);
  }
  .rb-info:not(.rb-flex-wrap-wrap) .help__question .help__question-answer:after {
    left: auto;
    right: -7px;
    transform: rotate(90deg);
  }
}

.rb-info.rb-flex-wrap-wrap {
  max-width: 350px;
}
.rb-info.rb-flex-wrap-wrap .help__question {
  top: 42px;
  right: -5px;
}

.help__trigger.active svg rect {
  transform: translate(1400px, 1049px);
  fill: #c6e870;
  transition: transform 0.3s ease, fill 0.3s ease;
  cursor: pointer;
}

.help__trigger.not-authenticated {
  bottom: 48px;
}
@media (min-width: 640px) {
  .help__trigger.not-authenticated {
    bottom: 43px;
  }
}

.help__trigger.near-bottom {
  transition: none;
  margin-bottom: 80px;
  position: absolute;
}
@media (min-width: 640px) {
  .help__trigger.near-bottom {
    margin-bottom: 0;
  }
}

.rb-btn-help-wrap {
  max-width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.quota-limit {
  position: relative;
  width: 100%;
  font-size: 16px;
  padding: 20px 15px;
  color: #000;
}
@media (min-width: 640px) {
  .quota-limit {
    font-size: 22px;
  }
}
.quota-limit a {
  text-decoration: underline;
}
.quota-limit a:hover {
  text-decoration: none;
}
.quota-limit-close {
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
}
.quota-limit-header {
  padding-right: 30px;
}
.quota-limit-header button {
  text-decoration: underline;
  cursor: pointer;
}
.quota-limit-header button:hover {
  text-decoration: none;
}
.quota-limit-header-menu {
  position: relative;
  background-color: #f9f9f9;
  list-style-type: none;
  padding: 20px;
  cursor: default;
  z-index: 10;
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.16);
  margin: 22px;
}
.quota-limit-header-menu-wrap {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin-left: -70px;
  height: 0;
  transition: height 0.5s ease-in-out;
}
.quota-limit-header-menu-wrap.active {
  height: 320px;
}
.quota-limit-header-menu-item {
  margin-bottom: 5px;
}
.quota-limit-header-menu-item-title {
  font-size: 16px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.quota-limit-header-menu-item-divider {
  border-bottom: 1px solid;
  margin-bottom: 10px;
  margin-top: 10px;
}
.quota-limit-header-menu-item b {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.quota-limit-wrap {
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 3;
  max-width: 300px;
  margin-top: 5rem;
  outline: 1px solid #D0D0D0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  top: 5rem;
  left: calc(50% - 150px);
}
@media (min-width: 640px) {
  .quota-limit-wrap {
    left: calc(50% - 195px);
    max-width: 390px;
  }
}
.quota-limit__title {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
@media (min-width: 640px) {
  .quota-limit__title {
    font-size: 22px;
  }
}
.quota-limit__text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  font-family: "Proxima Nova Light", sans-serif;
}
@media (min-width: 640px) {
  .quota-limit__text {
    font-size: 16px;
  }
}
.quota-limit-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.quota-used-100 .rb-notification-buttons-item {
  display: inline-block;
}

.rb-top-menu {
  display: flex;
  gap: 7px;
  align-items: center;
  position: relative;
  padding: 20px 0 12px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1148px;
}
.rb-top-menu-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.rb-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rb-logo img {
  width: 18px;
  height: 23px;
}
@media (min-width: 640px) {
  .rb-logo img {
    width: 26px;
    height: 33px;
    margin-left: 0;
    margin-right: 26px;
  }
}
.rb-logo__text {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 1.25rem;
}

.rb-menu-desktop {
  display: none;
}
@media (min-width: 640px) {
  .rb-menu-desktop {
    display: flex;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    width: calc(100% - 55px);
    justify-content: space-between;
  }
}
.rb-menu-desktop .links {
  display: flex;
  gap: 26px;
  align-items: center;
}
.rb-menu-desktop .links a {
  color: black;
  cursor: pointer;
}

.rb-user-card {
  display: flex;
  font-size: 14px;
  gap: 4px;
  cursor: pointer;
  height: 40px;
  align-items: center;
}
.rb-user-card:hover .arrow {
  color: #535856;
}
.rb-user-card:hover .rb-user-data {
  opacity: 0.6;
}
@media (max-width: 639px) {
  .rb-user-card p {
    max-width: 100px;
  }
}
.rb-user-card img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
@media (min-width: 640px) {
  .rb-user-card img {
    width: 36px;
    height: 36px;
  }
}
.rb-user-card .nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.rb-user-card .nav-list .nav-item {
  position: relative;
}
.rb-user-card .nav-list .nav-item-question a {
  margin-left: -9px !important;
}
.rb-user-card .nav-list .nav-item-question a svg {
  fill: #88938f;
}
.rb-user-card .nav-list .nav-item-question .dropbtn {
  padding-right: 11px;
}
.rb-user-card .nav-list .nav-item-question:hover svg {
  fill: #A7DD1E;
}
.rb-user-card .nav-list .nav-item a {
  display: block;
  color: black;
  text-align: center;
  padding: 13px 0 15px 0;
  text-decoration: none;
  margin-left: 10px;
}
.rb-user-card span {
  font-family: "Proxima Nova Semibold", sans-serif;
}

.rb-user-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}
.rb-user-data .email {
  color: #707070;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
  margin-top: 289px;
  width: 245px;
  right: 50px;
}
@media (min-width: 640px) {
  .dropdown-content {
    margin-top: 235px;
    right: 0;
  }
}
.dropdown-content a {
  font: normal normal normal 16px/20px "Proxima Nova", sans-serif;
  color: black;
  padding: 18px 20px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content .logout-btn {
  opacity: 0.5;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
}

.rb-menu-mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 640px) {
  .rb-menu-mobile {
    display: none;
  }
}

.dropdown-content-mobile {
  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
  margin-top: 255px;
  width: 160px;
  right: 0;
}
.dropdown-content-mobile a {
  font: normal normal normal 16px/20px "Proxima Nova", sans-serif;
  color: black;
  padding: 18px 20px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content-mobile .logout-btn {
  opacity: 0.5;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
}

.arrow {
  margin-left: 5px;
  color: #88938F;
}

.arrow-mobile {
  margin-left: 5px;
  color: #88938F;
}

.rb-menu-mobile__toggle {
  cursor: pointer;
}

.rb-menu-mobile__toggle.open ~ .rb-menu-mobile__items {
  max-height: 30vh;
}

.links.open .rb-menu-desktop__items {
  max-height: 32.5vh;
}

.rb-menu-mobile__items, .rb-menu-desktop__items {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 252px;
  left: 0;
  top: 73px;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition: max-height 300ms ease-out;
  font-size: 1rem;
  font-weight: 500;
  gap: 1.5rem;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 100;
  box-shadow: 0px 16px 16px -16px rgba(0, 0, 0, 0.1019607843);
}
.rb-menu-mobile__items > *:first-child, .rb-menu-desktop__items > *:first-child {
  margin-top: 1.5rem;
}
.rb-menu-mobile__items > *:last-child, .rb-menu-desktop__items > *:last-child {
  margin-bottom: 2rem;
}

.rb-not-authentication-buttons a {
  font-size: 14px;
  line-height: 17px;
  padding: 12px 29px 10px;
  font-family: "Proxima Nova Medium", sans-serif;
}
@media (max-width: 639px) {
  .rb-not-authentication-buttons a {
    padding: 4px 12px 4px;
    font-size: 12px;
    line-height: 15px;
  }
}
.rb-not-authentication-buttons .rb-btn-black {
  margin-right: 10px;
}
@media (max-width: 639px) {
  .rb-not-authentication-buttons .rb-btn-black {
    margin-right: 5px;
  }
}
.rb-not-authentication-buttons .rb-btn-black:hover {
  border-color: rgba(112, 112, 112, 0.2);
}
.rb-not-authentication-buttons .rb-btn-white {
  border-color: rgba(112, 112, 112, 0.2);
}

.footer {
  background-color: #1B3028;
  color: #ffffff;
}
.footer__container {
  display: block;
  position: relative;
  padding: 20px 20px 12px 20px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1148px;
}
.footer-top {
  padding-top: 45px;
  padding-bottom: 55.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991px) {
  .footer-top {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .footer-top {
    justify-content: center;
  }
}
.footer-top-left {
  padding-right: 20px;
}
@media (max-width: 1050px) {
  .footer-top-left {
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .footer-top-left {
    padding-right: 0;
  }
}
.footer-top-left-title {
  font-family: "Arial", sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 8px;
}
.footer-top-left-input {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: no-repeat;
  border-radius: 4px;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #ffffff;
  padding-left: 9px;
  padding-right: 9px;
  max-width: 284px;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova Light", sans-serif;
  box-sizing: border-box;
  height: 32px;
}
@media (max-width: 1119px) {
  .footer-top-left-input {
    max-width: 196px;
  }
}
.footer-top-left-input-error {
  color: #dc3232;
  font-size: 14px;
  font-weight: normal;
  display: block;
  position: absolute;
}
.footer-top-left-input-success {
  color: green;
  font-size: 14px;
  font-weight: normal;
  display: block;
  position: absolute;
}
.footer-top-left-submit {
  padding: 7px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 20px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
.footer-top-left-submit svg {
  fill: #88938f;
  padding-left: 0;
  font-style: normal;
}
.footer-top-left-submit:hover {
  background-color: #ffffff;
}
.footer-top-left-submit:hover svg {
  fill: #000000;
}
.footer-top-left form {
  display: flex;
}
.footer-top-center {
  align-self: flex-end;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1050px) {
  .footer-top-center {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .footer-top-center {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 60px);
    text-align: left;
  }
}
.footer-top-center-title {
  font-size: 18px;
  line-height: 21px;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  padding-bottom: 8px;
}
@media (max-width: 767px) {
  .footer-top-center-title {
    text-align: left;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
  }
}
.footer-top-center-products {
  background-color: #88938F;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova Light Italic", sans-serif;
  padding-top: 6px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 7px;
  width: 203px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .footer-top-center-products {
    margin-left: auto;
    margin-right: auto;
    width: 197px;
    left: -15px;
  }
}
.footer-top-center-products:before {
  position: absolute;
  background-color: #88938F;
  right: -33px;
  top: -1px;
  width: 32px;
  height: 32px;
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-left: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.footer-top-center-products:after {
  position: absolute;
  background-image: url(/static/public/images/svg/products_arrow.svg);
  right: -22px;
  top: 11px;
  width: 10px;
  height: 8px;
  content: "";
}
.footer-top-center-products ul {
  list-style-type: none;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;
  position: absolute;
  background-color: #88938F;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: calc(100% + 34px);
  left: -1px;
  padding-left: 8px;
  padding-right: 8px;
  top: 100%;
}
.footer-top-center-products ul li {
  display: flex;
  margin-top: 5px;
  padding-bottom: 2px;
}
.footer-top-center-products ul li a {
  color: #fff;
  font-family: "Proxima Nova", sans-serif;
  padding-left: 0;
  padding-right: 0;
}
.footer-top-center-products ul li a:hover {
  text-decoration: underline;
}
.footer-top-center-products:hover {
  border-bottom-left-radius: 0;
  border-right-color: #88938F;
}
.footer-top-center-products:hover:before {
  border-bottom-right-radius: 0;
  border-left-color: #88938F;
}
.footer-top-center-products:hover:after {
  transform: rotate(180deg);
}
.footer-top-center-products:hover ul {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top: 0;
}
.footer-top-center a {
  padding-left: 9.65px;
  padding-right: 9.65px;
  text-decoration: none;
}
@media (max-width: 1050px) {
  .footer-top-center a {
    padding-left: 7.65px;
    padding-right: 7.65px;
  }
}
.footer-top-center a span {
  position: relative;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.footer-top-center a span svg {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  left: -2px;
  position: relative;
  top: -2px;
  fill: #1B3028;
}
.footer-top-center a span svg:hover {
  fill-opacity: 0.9;
}
.footer-top-right {
  padding-left: 20px;
}
@media (max-width: 1050px) {
  .footer-top-right {
    padding-left: 10px;
  }
}
@media (max-width: 991px) {
  .footer-top-right {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-left: 0;
  }
}
.footer-top-right-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-top-right-menu li a {
  font-family: "Proxima Nova Light", sans-serif;
  line-height: 20px;
  text-decoration: none;
  color: #ffffff;
  margin-left: 32px;
}
@media (max-width: 767px) {
  .footer-top-right-menu li a {
    margin-left: 0;
    margin-right: 8px;
  }
}
.footer-top-right-menu li a:hover {
  border-bottom: 1px solid;
}
.footer-center {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
  .footer-center {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .footer-center-left {
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
.footer-center-left-social {
  display: block;
  padding-left: 58px;
}
@media (max-width: 767px) {
  .footer-center-left-social {
    padding-left: 10px;
  }
}
.footer-center-left-social a {
  padding-left: 9.65px;
  padding-right: 9.65px;
  text-decoration: none;
}
.footer-center-left-social a span {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}
.footer-center-left-social a span span {
  position: absolute;
  background-color: #fff;
  left: 0;
  z-index: 0;
  top: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
}
.footer-center-left-social a span svg {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  left: -2px;
  position: relative;
  top: -2px;
  fill: #1B3028;
  z-index: 1;
}
.footer-center-left-social a span svg:hover {
  fill-opacity: 0.9;
}
.footer-center ul {
  display: flex;
  list-style-type: none;
}
@media (max-width: 767px) {
  .footer-center ul {
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
.footer-center ul li {
  position: relative;
  padding-left: 20px;
}
@media (max-width: 767px) {
  .footer-center ul li {
    padding-left: 0;
    padding-right: 20px;
  }
}
.footer-center ul li:after {
  content: "|";
  color: #fff;
  position: relative;
  margin-left: 15px;
  top: 2px;
}
.footer-center ul li a {
  font-size: 14px;
  line-height: 16px;
  font-family: "Arial", sans-serif;
  text-decoration: none;
  color: #ffffff;
}
.footer-center ul li a:hover {
  text-decoration: underline;
}
.footer-center ul li:last-child:after {
  content: none;
}
@media (max-width: 767px) {
  .footer-center ul li:last-child {
    padding-right: 0;
  }
}
.footer-bottom {
  font-size: 14px;
  line-height: 16px;
  font-family: "Arial", sans-serif;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .footer-bottom {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .grecaptcha-badge {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}

.landing__container {
  display: block;
  position: relative;
  padding: 20px 20px 12px 20px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1148px;
}

.landing.active {
  height: 100%;
}

.learn-more {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 35px);
  cursor: pointer;
}
@media (max-width: 639px) {
  .learn-more {
    bottom: 20px;
  }
}
.learn-more span {
  color: #707070;
  font-size: 14px;
  line-height: 17px;
  display: block;
}
.learn-more img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
}

.guaranteed {
  text-align: center;
  padding-top: 63px;
}
@media (max-width: 991px) {
  .guaranteed {
    padding-top: 13px;
  }
}
.guaranteed__title {
  font-size: 26px;
  line-height: 50px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
@media (max-width: 639px) {
  .guaranteed__title {
    font-size: 23px;
  }
}
.guaranteed__text {
  max-width: 846px;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.guaranteed__blocks {
  padding-top: 50px;
}
@media (max-width: 991px) {
  .guaranteed__blocks {
    padding-top: 10px;
  }
}
.guaranteed__blocks-item {
  margin-bottom: 100px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
}
.guaranteed__blocks-item-content {
  min-width: 542px;
  max-width: 636px;
  background-color: #F7F7F7;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 397px;
  padding-top: 83px;
  padding-left: 70px;
  padding-right: 68px;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item-content {
    max-width: 100%;
  }
}
@media (max-width: 639px) {
  .guaranteed__blocks-item-content {
    min-width: auto;
    padding-top: 40px;
    padding-left: 35px;
    padding-right: 35px;
  }
}
.guaranteed__blocks-item-content .border-top, .guaranteed__blocks-item-content .border-bottom {
  background-color: rgba(112, 112, 112, 0.2);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}
.guaranteed__blocks-item-content .border-left, .guaranteed__blocks-item-content .border-right {
  background-color: rgba(112, 112, 112, 0.2);
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.guaranteed__blocks-item-content .border-bottom {
  bottom: 0;
  top: auto;
}
.guaranteed__blocks-item-content .border-right {
  left: auto;
  right: 0;
}
.guaranteed__blocks-item-content .scalloped-top, .guaranteed__blocks-item-content .scalloped-bottom {
  position: absolute;
  background: radial-gradient(ellipse at center, #fff 42%, rgba(112, 112, 112, 0.2) 50%, transparent 0%);
  background-size: 35.8px 30px;
  background-repeat: repeat-x;
  width: 100%;
  height: 31px;
  left: 12px;
  max-width: calc(100% - 22px);
}
.guaranteed__blocks-item-content .scalloped-left, .guaranteed__blocks-item-content .scalloped-right {
  position: absolute;
  background: radial-gradient(ellipse at center, #fff 42%, rgba(112, 112, 112, 0.2) 50%, transparent 0%);
  background-size: 30px 31.5px;
  background-repeat: repeat-y;
  width: 31px;
  height: 100%;
  top: 12px;
  max-height: calc(100% - 16px);
}
.guaranteed__blocks-item-content .scalloped-top {
  top: -16px;
}
.guaranteed__blocks-item-content .scalloped-bottom {
  bottom: -16px;
}
.guaranteed__blocks-item-content .scalloped-left {
  left: -16px;
}
.guaranteed__blocks-item-content .scalloped-right {
  right: -16px;
}
.guaranteed__blocks-item-texts {
  max-width: 430px;
  padding-right: 20px;
  text-align: left;
  color: #000;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item-texts {
    max-width: 100%;
    padding-right: 0;
  }
}
.guaranteed__blocks-item-texts-title {
  font-family: "Arial, Helvetica", sans-serif;
  font-size: 36px;
  line-height: 42px;
  max-width: 390px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item-texts-title {
    max-width: 100%;
  }
}
.guaranteed__blocks-item-texts-text {
  font-size: 18px;
  line-height: 26px;
  font-family: "Proxima Nova Light", sans-serif;
  margin-top: 28px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item-texts-text {
    padding-bottom: 30px;
  }
}
.guaranteed__blocks-item-texts-text b {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-weight: normal;
}
.guaranteed__blocks-item-secure .guaranteed__blocks-item-content {
  padding: 35px;
}
.guaranteed__blocks-item-secure .guaranteed__blocks-item-texts {
  max-width: 452px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-item-secure .guaranteed__blocks-item-texts {
    max-width: 100%;
  }
}
.guaranteed__blocks-text-right .guaranteed__blocks-item-texts {
  padding-right: 0;
  padding-left: 20px;
}
@media (max-width: 991px) {
  .guaranteed__blocks-text-right .guaranteed__blocks-item-texts {
    padding-left: 0;
    order: -1;
  }
}
.guaranteed__blocks-text-right .guaranteed__blocks-item-content {
  padding: 35px;
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-top {
    display: block;
  }
}
.guaranteed__fingerprint-top-browse {
  position: relative;
}
@media (min-width: 1120px) {
  .guaranteed__fingerprint-top-browse {
    margin-left: -40px;
  }
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-top-browse {
    max-width: 161px;
    margin-left: auto;
    margin-right: auto;
  }
}
.guaranteed__fingerprint-top-browse span {
  position: absolute;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  left: 53px;
  top: 18px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.guaranteed__fingerprint-top-arrow {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-top-arrow {
    margin-top: 55px;
    transform: rotate(90deg);
    margin-bottom: 55px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: -57px;
    position: relative;
  }
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-top-arrow-wrap {
    overflow: hidden;
    max-height: 90px;
    margin-top: 10px;
  }
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-top-fingerprint {
    margin-left: -20px;
  }
}
.guaranteed__fingerprint-bottom {
  margin-top: 36px;
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-bottom {
    display: block;
  }
}
.guaranteed__fingerprint-bottom-item {
  max-width: 218px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
}
@media (max-width: 639px) {
  .guaranteed__fingerprint-bottom-item {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.guaranteed__fingerprint-bottom-item span {
  margin-bottom: 13px;
  display: block;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.guaranteed__fingerprint-bottom-item p {
  font-family: "Proxima Nova Light", sans-serif;
}
.guaranteed__stamp {
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 41px;
  padding-left: 38px;
  padding-right: 38px;
}
@media (max-width: 639px) {
  .guaranteed__stamp {
    padding: 0;
    background: none;
  }
}
.guaranteed__stamp .rb-result-img {
  margin-top: 28px;
}

.landing__three {
  background-color: #EDF0E5;
  padding-top: 80px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .landing__three {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
.landing__three-title {
  font-size: 36px;
  line-height: 50px;
  font-family: "Arial, Helvetica", sans-serif;
  max-width: 846px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 19px;
}
.landing__three-text {
  font-size: 18px;
  line-height: 26px;
  font-family: "Proxima Nova Light", sans-serif;
  max-width: 846px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
@media (max-width: 639px) {
  .landing__three-blocks {
    display: block;
  }
}
.landing__three-blocks-item {
  background-color: #fff;
  padding-top: 50px;
  padding-left: 28px;
  padding-right: 24px;
  padding-bottom: 100px;
  width: calc(33.33333% - 22px);
}
@media (max-width: 991px) {
  .landing__three-blocks-item {
    padding-bottom: 50px;
  }
}
@media (max-width: 639px) {
  .landing__three-blocks-item {
    width: 100%;
    margin-bottom: 30px;
  }
}
.landing__three-blocks-item-content {
  height: 100%;
}
.landing__three-blocks-item-content img {
  margin-bottom: 18px;
}
.landing__three-blocks-item-content span {
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #000000;
  margin-bottom: 20px;
}
.landing__three-blocks-item-content p {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-family: "Proxima Nova", sans-serif;
}

.supplementary-information__title {
  max-width: 100%;
  margin-bottom: 35px;
}
.supplementary-information__blocks-item {
  padding-bottom: 38px;
  padding-left: 25px;
  padding-right: 22px;
}

.blocks-reviews, .customer__reviews {
  padding-top: 70px;
  padding-bottom: 64px;
  border-bottom: 1px solid #EDF0E5;
}
@media (max-width: 639px) {
  .blocks-reviews, .customer__reviews {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.blocks-reviews-title, .customer__reviews-title {
  font-size: 36px;
  line-height: 42px;
  font-family: "Arial, Helvetica", sans-serif;
  color: #000;
}
.blocks-reviews .reviews, .customer__reviews .reviews {
  margin-top: 82px;
  padding-bottom: 20px;
}
@media (max-width: 639px) {
  .blocks-reviews .reviews, .customer__reviews .reviews {
    margin-top: 20px;
  }
}
.blocks-reviews .reviews-item, .customer__reviews .reviews-item {
  flex: 0 0 auto;
  width: 100%;
}
.blocks-reviews .reviews-item p, .customer__reviews .reviews-item p {
  font-size: 22px;
  line-height: 32px;
  font-family: "Proxima Nova Light", sans-serif;
  margin-top: 0;
  margin-bottom: 19px;
  color: #000;
}
@media (max-width: 639px) {
  .blocks-reviews .reviews-item p, .customer__reviews .reviews-item p {
    font-size: 16px;
    line-height: 26px;
  }
}
.blocks-reviews .reviews-item-author, .customer__reviews .reviews-item-author {
  padding-top: 19px;
}
.blocks-reviews .reviews-item-author-photo img, .customer__reviews .reviews-item-author-photo img {
  border-radius: 50%;
  margin-right: 12px;
}
.blocks-reviews .reviews-item-author-name, .customer__reviews .reviews-item-author-name {
  color: #333333;
  line-height: 20px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
}
.blocks-reviews .reviews-item-author-name span, .customer__reviews .reviews-item-author-name span {
  display: block;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 14px;
  line-height: 23px;
  color: #707070;
  padding-top: 6px;
}
.blocks-reviews .reviews-wrap, .customer__reviews .reviews-wrap {
  max-width: calc(76.9% - 40px);
  overflow: hidden;
}
@media (max-width: 639px) {
  .blocks-reviews .reviews-wrap, .customer__reviews .reviews-wrap {
    max-width: calc(100% - 60px);
  }
}
.blocks-reviews-nav, .customer__reviews-nav {
  z-index: 1;
}
@media (max-width: 639px) {
  .blocks-reviews-nav-button, .customer__reviews-nav-button {
    width: 30px;
  }
}
.blocks-reviews-nav-button img, .customer__reviews-nav-button img {
  cursor: pointer;
}
.blocks-reviews-nav-button:hover img, .customer__reviews-nav-button:hover img {
  opacity: 0.4;
}
.blocks-reviews-nav-prev, .customer__reviews-nav-prev {
  margin-left: 10px;
  margin-right: 6px;
  transform: rotate(180deg);
}
@media (max-width: 639px) {
  .blocks-reviews-nav-prev, .customer__reviews-nav-prev {
    margin-left: 0;
  }
}
.blocks-reviews-nav-next, .customer__reviews-nav-next {
  margin-right: 10px;
}
@media (max-width: 639px) {
  .blocks-reviews-nav-next, .customer__reviews-nav-next {
    margin-right: 0;
  }
}

.pricing {
  padding-bottom: 88px;
  padding-top: 61px;
}
@media (max-width: 991px) {
  .pricing {
    padding-bottom: 44px;
    padding-top: 30px;
  }
}
.pricing__title {
  text-align: center;
  padding-bottom: 15px;
  font-size: 36px;
  line-height: 42px;
  font-family: "Arial, Helvetica", sans-serif;
}
.pricing__text {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-family: "Proxima Nova Light", sans-serif;
  padding-bottom: 60px;
  color: #333333;
}
@media (max-width: 991px) {
  .pricing-block {
    justify-content: center;
  }
}
@media (max-width: 639px) {
  .pricing-block {
    display: block;
  }
}
.pricing-block-item {
  flex-direction: column;
  width: calc(33.33333% - 20px);
  padding-left: 38px;
  padding-right: 43px;
  padding-top: 33px;
  padding-bottom: 57px;
}
@media (max-width: 991px) {
  .pricing-block-item {
    width: calc(50% - 18px);
  }
}
@media (max-width: 639px) {
  .pricing-block-item {
    margin-left: auto;
    margin-right: auto !important;
    width: 100%;
    max-width: 366px;
    padding: 30px;
  }
}
.pricing-block-item-first {
  border: 1px solid rgba(112, 112, 112, 0.2);
}
@media (max-width: 991px) {
  .pricing-block-item-first {
    margin-right: 31px;
  }
}
@media (max-width: 639px) {
  .pricing-block-item-first {
    margin-right: 31px;
  }
}
.pricing-block-item-second {
  background-color: #1B2F28;
  color: #fff;
}
@media (max-width: 639px) {
  .pricing-block-item-second {
    margin-top: 31px;
  }
}
.pricing-block-item-second .pricing-block-buttons .button:hover {
  background-color: #C6E870;
  color: #000;
}
.pricing-block-item-third {
  background-color: #C6E870;
}
@media (max-width: 991px) {
  .pricing-block-item-third {
    flex-basis: calc(33.33333% + 50px);
    margin-top: 31px;
  }
}
@media (max-width: 639px) {
  .pricing-block-item-third {
    flex-basis: calc(33.33333% + 50px);
    margin-top: 31px;
  }
}
.pricing-block-item-third .pricing-block-item-body-item svg {
  fill: #1B2F28;
}
.pricing-block-item-head {
  margin-bottom: 19px;
  min-height: 64px;
}
@media (max-width: 639px) {
  .pricing-block-item-head {
    margin-bottom: 5px;
  }
}
.pricing-block-item-head-title {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
}
.pricing-block-item-head-subtitle {
  font-family: "Proxima Nova Light", sans-serif;
  line-height: 23px;
  font-size: 16px;
  padding-top: 4px;
}
.pricing-block-item-body {
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  padding-top: 33px;
}
@media (max-width: 639px) {
  .pricing-block-item-body {
    padding-top: 10px;
  }
}
.pricing-block-item-body-item {
  padding-bottom: 20px;
}
.pricing-block-item-body-item svg {
  margin-right: 13px;
  position: relative;
  top: 5px;
  fill: #E6E6E6;
  height: 10px;
}
.pricing-block-item-body-item-text {
  line-height: 22px;
}
.pricing-block-item-body-item-text span {
  display: block;
}
.pricing-block-item-footer {
  margin-top: auto;
}
.pricing-block-item-footer .pricing-block-buttons {
  margin-top: 50px;
}
@media (max-width: 639px) {
  .pricing-block-item-footer .pricing-block-buttons {
    margin-top: 20px;
  }
}
.pricing-block-item-footer .pricing-block-buttons .button {
  border-radius: 4px;
  line-height: 20px;
  padding: 12px 42px 10px 42px;
  text-decoration: none;
  font-size: 18px;
}
.pricing-block-item-footer .pricing-block-buttons .button-white {
  background-color: #fff;
  border: 1px solid #1b3028;
  color: #000;
}
.pricing-block-item-footer .pricing-block-buttons .button-white:hover {
  background-color: #1b3028;
  color: #fff;
}
.pricing-block-item-footer .pricing-block-buttons .button-dark {
  background-color: #1b3028;
  border: 1px solid #1b3028;
  color: #fff;
}
.pricing-block-item-footer .pricing-block-buttons .button-dark:hover {
  background-color: #fff;
  color: #1b3028;
}
.pricing-block-item-second .pricing-block-buttons .button-white:hover {
  background-color: #c6e870;
  color: #000;
}

.transform-data-security {
  padding-top: 66px;
  padding-bottom: 70px;
}
@media (max-width: 991px) {
  .transform-data-security {
    padding-top: 33px;
    padding-bottom: 35px;
  }
}
.transform-data-security__blocks {
  background-color: rgba(237, 237, 237, 0.4);
  border: 1px solid #C6E870;
  border-radius: 2px;
}
@media (max-width: 639px) {
  .transform-data-security__blocks {
    display: block;
  }
}
.transform-data-security__blocks-texts {
  padding-left: 88px;
  padding-top: 38px;
  padding-right: 20px;
  padding-bottom: 47px;
  max-width: 603px;
}
@media (max-width: 639px) {
  .transform-data-security__blocks-texts {
    padding-left: 20px;
  }
}
.transform-data-security__blocks-texts span {
  display: block;
  font-size: 36px;
  line-height: 42px;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin-bottom: 21px;
  color: #000;
}
.transform-data-security__blocks-texts p {
  font-size: 20px;
  line-height: 24px;
  font-family: "Proxima Nova Light", sans-serif;
  margin-bottom: 6px;
  color: #000;
}
.transform-data-security__blocks-img {
  min-width: 280px;
}

.pricing-page {
  padding-top: 100px;
}
.pricing-page .rb-container {
  max-width: 1148px;
}
.pricing-page-header {
  margin-bottom: 57px;
}
.pricing-page-header img {
  max-width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.pricing-page-periods {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.pricing-page-periods-item {
  border: 1px solid #fff;
  width: 107px;
}
.pricing-page-periods-active {
  background-color: #1B2F28;
}
.pricing-page-periods-active label {
  color: #fff;
}
.pricing-page-periods-month {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
}
.pricing-page-periods-year {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: none;
}
.pricing-page-plans {
  margin-left: auto;
  margin-right: auto;
}
.pricing-page-plans-item-buttons {
  margin-top: 20px !important;
}
.pricing-page-plans-item-first {
  background-color: #EDF0E5;
}
.pricing-page-plans-item-first .pricing-block-item-body-item svg {
  fill: #1B2F28;
}
@media (max-width: 991px) {
  .pricing-page-plans-item-third {
    flex-basis: calc(50% - 5px);
  }
}
.pricing-page-plans-item-third .pricing-page-block-item-head-current {
  background-color: #fff;
}
.pricing-page-plans-item-third .pricing-page-block-item-footer svg {
  fill: #1B2F28;
}
.pricing-page-plans-item-third .pricing-page-block-item-footer svg path {
  stroke: #fff;
}
.pricing-page-plans-custom {
  width: 100%;
  overflow: hidden;
  padding-left: 8.9%;
  margin-top: 50px;
  border: 1px solid #C6E870;
  background-color: #FFFFFF;
  position: relative;
  padding-bottom: 54px;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom {
    padding-left: 30px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .pricing-page-plans-custom {
    display: block;
    padding-bottom: 280px;
  }
}
.pricing-page-plans-custom-item-first {
  width: 31%;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom-item-first {
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-first {
    width: 230px;
  }
}
.pricing-page-plans-custom-item-title {
  font-size: 42px;
  line-height: 48px;
  font-family: "Proxima Nova Semibold", sans-serif;
  padding-top: 65px;
  margin-bottom: 35px;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom-item-title {
    padding-top: 25px;
    font-size: 36px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-buttons {
    display: none;
  }
}
.pricing-page-plans-custom-item-buttons .button {
  background-color: #1B3028;
  color: #FFFFFF;
  font-size: 18px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 48px;
  padding-right: 48px;
  border: 1px solid #1b3028;
}
.pricing-page-plans-custom-item-buttons .button:hover {
  background-color: #fff;
  color: #1B3028;
}
.pricing-page-plans-custom-item-second {
  padding-top: 72px;
  position: relative;
  z-index: 1;
  max-width: 31%;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom-item-second {
    padding-top: 32px;
  }
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-second {
    width: 230px;
    padding-top: 0;
    max-width: none;
  }
}
.pricing-page-plans-custom-item-line {
  padding-bottom: 18px;
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-line {
    display: none;
  }
}
.pricing-page-plans-custom-item-line svg {
  margin-right: 13px;
  position: relative;
  top: 5px;
  fill: #1B2F28;
  height: 10px;
}
.pricing-page-plans-custom-item-line-text {
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 18px;
  line-height: 21px;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom-item-line-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.pricing-page-plans-custom-item-line-text b {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-weight: normal;
}
.pricing-page-plans-custom-item-img {
  position: absolute;
  right: -57px;
}
@media (max-width: 991px) {
  .pricing-page-plans-custom-item-img {
    max-width: 360px;
    bottom: -113px;
  }
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-img {
    bottom: -71px;
    right: -70px;
  }
}
.pricing-page-plans-custom-item-mobile {
  display: none;
}
@media (max-width: 767px) {
  .pricing-page-plans-custom-item-mobile {
    display: block;
  }
}
.pricing-page-plans-custom-item-mobile span {
  display: block;
  font-family: "Proxima Nova Light", sans-serif;
}
.pricing-page-plans-custom-item-mobile a {
  text-decoration: underline;
  font-size: 18px;
}
.pricing-page-plans-custom-item-mobile a:hover {
  text-decoration: none;
}
@media (max-width: 1169px) {
  .pricing-page-block-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pricing-page-block-item-head {
  margin-bottom: 19px;
}
.pricing-page-block-item-head-subtitle {
  font-size: 40px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.pricing-page-block-item-head-subtitle span {
  font-family: "Proxima Nova Light", sans-serif;
}
.pricing-page-block-item-head-subtitle-year {
  padding-top: 20px;
  width: 100%;
  font-size: 20px;
}
@media (max-width: 1169px) {
  .pricing-page-block-item-head-subtitle-year {
    font-size: 17px;
    line-height: 30px;
  }
}
.pricing-page-block-item-head-wrap {
  width: 100%;
  padding-top: 18px;
  min-height: 91px;
}
.pricing-page-block-item-head-current {
  background-color: #C6E870;
  width: auto;
  color: #000;
  font-size: 14px;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 11px;
  padding-right: 11px;
  min-width: 101px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.pricing-page-block-item-footer svg {
  fill: #c6e870;
  margin-top: 5px;
  top: 5px;
  position: relative;
}
.pricing-page-block-item-footer svg path {
  stroke: #1b2f28;
}
.pricing-page .back-free-window {
  width: 300px;
  height: 200px;
  position: fixed;
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  margin-top: 5rem;
  outline: 1px solid #D0D0D0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.pricing-page .back-free-window__title {
  width: 100%;
  text-align: center;
  font-size: 22px;
}
.pricing-page .back-free-window__buttons button {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.pricing-page-diy {
  padding-top: 13px;
  padding-bottom: 14px;
  padding-left: 18px;
  padding-right: 20px;
  border: 1px solid #D4D6CD;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 21px;
  text-align: center;
  margin-bottom: 26px;
  background-color: rgba(237, 240, 229, 0.6);
}
.pricing-page-diy img {
  width: 28px;
  height: 28px;
  padding-right: 10px;
}
.pricing-page-diy span {
  font-family: "Proxima Nova Semibold", sans-serif;
  padding-right: 10px;
}
.pricing-page-diy p {
  margin: 0;
  padding-right: 10px;
}
.pricing-page-diy a {
  text-decoration: underline;
}
.pricing-page-diy a:hover {
  text-decoration: none;
}

.rb-subscription-success .rb-result-img {
  margin-top: 0;
}
.rb-subscription-success-buttons {
  margin-top: 20px;
}
.rb-subscription-success-buttons .rb-btn {
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 10px;
  margin-left: 10px;
}
.rb-subscription-success .rb-details-table {
  padding-bottom: 30px;
}
.rb-subscription-success .rb-details-table__item__value, .rb-subscription-success .rb-details-table__item__value a {
  min-width: auto;
}
.rb-subscription-success .rb-details-table__dotted-space {
  margin-left: 9px;
}
.rb-subscription-success .rb-container {
  width: 100%;
  margin-top: 30px;
}
@media (min-width: 640px) {
  .rb-subscription-success .rb-container {
    max-width: 720px;
    padding: 0 40px 60px 40px;
  }
}
.rb-subscription-success .rb-details-table__dotted-space {
  height: 12px;
}

.reports {
  margin-top: 100px;
}
.reports-page {
  padding-bottom: 51px;
}
@media (min-width: 640px) {
  .reports-page .rb-container {
    max-width: 1148px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.reports h1 {
  font-size: 46px;
  line-height: 54px;
  font-weight: normal;
  margin-bottom: 10px;
}
.reports h1 a {
  text-decoration: underline;
}
.reports h1 a:hover {
  text-decoration: none;
}
.reports h2 {
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  margin-bottom: 30px;
}
.reports h3 {
  font-size: 22px;
  line-height: 27px;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin-bottom: 24px;
  font-weight: normal;
}
.reports-text {
  max-width: 785px;
  margin-bottom: 50px;
}
.reports-text p {
  font-size: 18px;
  line-height: 22px;
  padding-top: 7px;
}
.reports-content {
  border: 1px solid rgba(112, 112, 112, 0.2);
  background-color: #fff;
  padding: 36px 40px 98px 42px;
  position: relative;
}
.reports-content .html-table {
  margin: 0;
}
.reports-content .html-table svg {
  opacity: 0.6;
  margin-right: 15px;
}
.reports-all {
  text-align: right;
  margin-top: 20px;
}
@media (max-width: 1050px) {
  .reports-all {
    text-align: left;
  }
}
.reports-all-title {
  line-height: 22px;
  padding-bottom: 7px;
}
@media (max-width: 1050px) {
  .reports-all-content {
    justify-content: flex-start;
  }
}
.reports-all-content-img {
  width: 29px;
  height: 29px;
  background-color: #EDF0E5;
  border-radius: 50%;
  margin-right: 8px;
}
.reports-all-content-img-no {
  background-color: #1B2F28;
}
.reports-head {
  margin-bottom: 60px;
}
@media (max-width: 1050px) {
  .reports-head {
    display: block;
  }
}
.reports-head-left-block {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}
.reports-head-left-block img {
  width: 24px;
  margin-left: 10px;
}
.reports-head-left-block span {
  display: block;
  color: #000;
  margin-bottom: 7px;
}
.reports-head-left-block b {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin-right: 9px;
}
.reports-head-left-block a {
  text-decoration: underline;
}
.reports-head-left-block a:hover {
  text-decoration: none;
}
.reports-head-left-block-release a {
  font-size: 20px;
}
.reports-blocks {
  width: 601px;
}
@media (max-width: 767px) {
  .reports-blocks {
    display: block;
    width: 100%;
  }
}
.reports-block {
  position: relative;
  width: 187px;
  height: 128px;
  padding-left: 20px;
  padding-top: 16px;
  padding-right: 35px;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .reports-block {
    margin-bottom: 20px;
  }
}
.reports-block b {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
}
.reports-block .help__question.top-right {
  top: 17.5px;
  right: 12px;
  color: #000;
}
.reports-block .help__question-mark {
  width: 17.5px;
  height: 17.5px;
}
.reports-block .help__question-mark span {
  top: 1px;
  font-size: 12px;
}
.reports-block-number {
  position: absolute;
  bottom: 25px;
  left: 20px;
  font-size: 30px;
  line-height: 25px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.reports-table-head {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .reports-table-head {
    display: block;
  }
}
.reports-table-head-left {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .reports-table-head-left {
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .reports-table-head-right {
    display: flex;
    flex-wrap: wrap;
  }
}
.reports-table-head-right a {
  text-decoration: underline;
  line-height: 20px;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .reports-table-head-right a {
    padding-left: 0;
    padding-right: 30px;
  }
}
.reports-table-head-right a:hover {
  text-decoration: none;
}
.reports-producer .reports-blocks {
  width: 394px;
}
@media (max-width: 767px) {
  .reports-producer .reports-blocks {
    width: 100%;
  }
}
.reports-producer .reports-all {
  text-align: left;
}
.reports-producer .reports-all a {
  text-decoration: underline;
}
.reports-producer .reports-all a:hover {
  text-decoration: none;
}
.reports-buttons {
  margin-top: 20px;
}
.reports-buttons .button {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid transparent;
  width: 116px;
}
.reports-buttons .button svg {
  fill: #ffffff;
  transform: rotate(180deg);
  margin-right: 10px;
}
.reports-buttons .button:hover {
  border-color: #000000;
  color: #000000;
  background-color: #ffffff;
}
.reports-buttons .button:hover svg {
  fill: #000000;
}

.user-found {
  margin-top: 0;
}
.user-found .reports-text {
  margin: 0 auto;
  padding-bottom: 30px;
}
.user-found .reports-buttons .button {
  width: 155px;
}
@media (max-width: 1050px) {
  .user-found .reports-head {
    display: flex;
  }
}
@media (max-width: 767px) {
  .user-found .reports-head {
    display: block;
  }
}
.user-found .reports-head-left {
  max-width: 70%;
}
@media (max-width: 767px) {
  .user-found .reports-head-left {
    max-width: 100%;
  }
}
.user-found .reports-head-left-block span {
  display: inline-block;
}
.user-found .reports-head-left-block b {
  font-size: 16px;
  padding-left: 5px;
}
@media (max-width: 767px) {
  .user-found .reports-head-left-block b {
    overflow: auto;
    display: block;
  }
}
.user-found .reports-table-head {
  margin-bottom: 0;
}
.user-found .reports-table-head-left .mantine-Tabs-list:before {
  content: none;
}
.user-found .reports-table-head-left .mantine-Tabs-list button {
  border: none;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  line-height: 22px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
@media (max-width: 991px) {
  .user-found .reports-table-head-left .mantine-Tabs-list button {
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.user-found .reports-table-head-left .mantine-Tabs-list button[data-active=true] {
  background-color: #EDF0E5;
  color: #000;
}
.user-found .reports-table-head-right {
  gap: 9px;
}
@media (max-width: 767px) {
  .user-found .reports-table-head-right {
    margin-bottom: 15px;
  }
}
.user-found .reports-table-head-right-link {
  color: #000;
  font-size: 14px;
  line-height: 17px;
  padding-right: 13px;
}
@media (max-width: 991px) {
  .user-found .reports-table-head-right-link {
    padding-right: 3px;
  }
}
.user-found .reports-table-head-right .mantine-Select-section {
  border-left: 1px solid rgba(112, 112, 112, 0.4);
}
.user-found .reports-table-head-right .mantine-Select-wrapper input {
  width: 400px;
}
@media (max-width: 767px) {
  .user-found .reports-table-head-right .mantine-Select-wrapper input {
    width: 100%;
  }
}
.user-found .reports-table-head-right [data-expanded=true] + .mantine-Select-section svg {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.user-found .reports-head-right {
  max-width: 30%;
}
@media (max-width: 767px) {
  .user-found .reports-head-right {
    max-width: 100%;
  }
}
.user-found .reports-head-right .reports-blocks {
  width: auto;
}
.user-found .reports-head-right .reports-blocks .reports-block {
  padding-left: 9px;
  padding-top: 9px;
  padding-right: 9px;
  padding-bottom: 7px;
  width: 128px;
  height: 70px;
  margin-bottom: 10px;
}
.user-found .reports-head-right .reports-blocks .reports-block b {
  font-size: 16px;
  line-height: 28px;
}
.user-found .reports-head-right .reports-blocks .reports-block-number {
  font-family: "Proxima Nova Medium", sans-serif;
  font-size: 22px;
  line-height: 28px;
  bottom: 3px;
  position: relative;
  display: block;
  left: 0;
}
.user-found .html-table table tr th:first-child {
  min-width: 150px;
}
.user-found .html-table table tr th:nth-child(2) {
  min-width: 300px;
}
.user-found .html-table table tr th:nth-child(3) {
  min-width: 120px;
}
.user-found .html-table table tr th:nth-child(4) {
  min-width: 160px;
}
.user-found .html-table table tr th:nth-child(5) {
  min-width: 140px;
}
.user-found .html-table-wrap-user-found .html-table table tr th:first-child {
  width: 300px;
}
.user-found .html-table-wrap-user-found .html-table table tr th:nth-child(2) {
  min-width: 120px;
  text-align: center;
}
.user-found .html-table-wrap-user-found .html-table table tr td:nth-child(2) {
  text-align: center;
}
.user-found .html-table-wrap-user-found .html-table table tr th:nth-child(3) {
  min-width: 120px;
  text-align: center;
}
.user-found .html-table-wrap-user-found .html-table table tr td:nth-child(3), .user-found .html-table-wrap-user-found .html-table table tr td:nth-child(2) {
  text-align: center;
}
.user-found .html-table-wrap-user-found .html-table table tr th:nth-child(4) {
  min-width: 220px;
}
.user-found .html-table-wrap-user-found .html-table table tr td:first-child, .user-found .html-table-wrap-user-found .html-table table tr td:nth-child(2) {
  cursor: pointer;
}
.user-found .html-table-wrap-stamp-history .html-table table tr td:nth-child(4), .user-found .html-table-wrap-stamp-history .html-table table tr td:last-child {
  cursor: pointer;
}
.user-found .rb-loading-wrap {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  position: absolute;
  max-width: none;
  margin-top: 0;
  max-height: none;
  min-height: 0;
  height: 100%;
  top: 0;
  left: 0;
}

.user-profile {
  background-color: #fff;
  padding-bottom: 0;
}
.user-profile-header {
  padding-top: 100px;
  padding-bottom: 30px;
}
.user-profile-tabs-buttons {
  width: 100%;
  border-bottom: 4px solid #E0E0E0;
}
.user-profile-tabs-buttons-block-wrap {
  position: relative;
  bottom: -4px;
  border-bottom: 4px solid #fff;
}
@media (max-width: 639px) {
  .user-profile-tabs-buttons-block-wrap {
    bottom: 0;
  }
}
.user-profile-tabs-buttons-block-wrap button {
  cursor: pointer;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
  padding-bottom: 10px;
  border-bottom: 4px solid #E0E0E0;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  bottom: -4px;
  font-family: "Proxima Nova Medium", sans-serif;
}
@media (max-width: 639px) {
  .user-profile-tabs-buttons-block-wrap button {
    width: calc(50% - 2px);
    margin-bottom: 15px;
    bottom: 0;
  }
}
.user-profile-tabs-buttons-block-wrap button.active {
  color: #000;
  border-color: #000;
  cursor: default;
}
.user-profile-tabs-content {
  width: 100%;
  background-color: #EDEDED;
  height: 100%;
}
.user-profile-tabs-content-item {
  padding-top: 83px;
  padding-bottom: 100px;
}
@media (max-width: 639px) {
  .user-profile-tabs-content-item {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
.user-profile-line {
  gap: 20px;
  margin-bottom: 32px;
}
@media (max-width: 639px) {
  .user-profile-line {
    flex-wrap: wrap;
    gap: 0;
  }
}
.user-profile-line label {
  font-size: 18px;
  font-family: "Proxima Nova Semibold", sans-serif;
  width: 236px;
  text-align: right;
}
@media (max-width: 991px) {
  .user-profile-line label {
    width: 160px;
  }
}
@media (max-width: 639px) {
  .user-profile-line label {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
}
.user-profile-line .rb-input {
  font-size: 18px;
  border-radius: 2px;
  background-color: #f8f9fa;
  font-family: "Proxima Nova", sans-serif;
  width: 350px;
  height: 42px;
}
@media (max-width: 991px) {
  .user-profile-line .rb-input {
    max-width: 250px;
  }
}
@media (max-width: 639px) {
  .user-profile-line .rb-input {
    max-width: 100%;
    width: 100%;
  }
}
.user-profile-line .rb-input-wrap {
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .user-profile-line .rb-input-wrap {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .user-profile-line .rb-input-wrap input {
    width: 100%;
  }
}
.user-profile-line .rb-input-wrap .rb-input-error {
  border-color: #C42905;
}
.user-profile-line .rb-input-wrap .rb-input-text-error {
  color: #C42905;
  position: absolute;
  line-height: 14px;
  font-size: 14px;
  font-family: "Proxima Nova Light", sans-serif;
}
.user-profile-line .rb-input-wrap .btn-show-hide {
  top: 1px;
  width: auto;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
@media (max-width: 639px) {
  .user-profile-line .rb-input-wrap .btn-show-hide i {
    top: -2px;
  }
}
.user-profile-line .disabled {
  border-color: rgba(112, 121, 112, 0.2);
  background-color: rgba(247, 247, 247, 0.8);
}
.user-profile-line button, .user-profile-line-link a {
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
  width: 65px;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .user-profile-line button, .user-profile-line-link a {
    white-space: normal;
    width: 100px;
  }
}
@media (max-width: 639px) {
  .user-profile-line button, .user-profile-line-link a {
    width: 100%;
    text-align: left;
    margin-top: 3px;
  }
}
.user-profile-line button span, .user-profile-line-link a span {
  overflow: initial;
}
.user-profile-line button:hover, .user-profile-line-link a:hover {
  text-decoration: none;
}
.user-profile-line-billing-history button {
  width: 100%;
  text-align: left;
}
.user-profile-line .no-active {
  z-index: -1;
}
.user-profile-line-date {
  margin-top: 60px;
}
@media (max-width: 639px) {
  .user-profile-line-date {
    margin-top: 30px;
  }
}
.user-profile-line-date span {
  width: 350px;
}
@media (max-width: 991px) {
  .user-profile-line-date span {
    width: 250px;
  }
}
@media (max-width: 639px) {
  .user-profile-line-date span {
    width: 100%;
  }
}
.user-profile-line-password, .user-profile-line-link {
  margin-top: 60px;
}
@media (max-width: 639px) {
  .user-profile-line-password, .user-profile-line-link {
    margin-top: 30px;
  }
}
.user-profile-line-password button, .user-profile-line-password a, .user-profile-line-link button, .user-profile-line-link a {
  width: 434px;
  text-align: left;
}
@media (max-width: 991px) {
  .user-profile-line-password button, .user-profile-line-password a, .user-profile-line-link button, .user-profile-line-link a {
    width: 337px;
  }
}
@media (max-width: 639px) {
  .user-profile-line-password button, .user-profile-line-password a, .user-profile-line-link button, .user-profile-line-link a {
    width: 100%;
  }
}
.user-profile-line-cancel {
  margin-top: 60px;
}
@media (max-width: 639px) {
  .user-profile-line-cancel {
    margin-top: 30px;
  }
}
.user-profile-line-cancel button {
  width: 434px;
  text-align: left;
  color: #FF0000;
}
@media (max-width: 991px) {
  .user-profile-line-cancel button {
    width: 337px;
  }
}
@media (max-width: 639px) {
  .user-profile-line-cancel button {
    width: 100%;
  }
}
.user-profile-line-cancel-choice {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -24px;
  left: -33px;
  position: relative;
}
.user-profile-line-cancel-message {
  margin-top: -20px;
  position: relative;
  left: -20px;
}
.user-profile .button-dark {
  margin-top: 20px;
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
}
.user-profile .button-dark svg {
  fill: #fff;
  transform: rotate(180deg);
  margin-right: 10px;
}
.user-profile .button-dark:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.user-profile .button-dark:hover:hover svg {
  fill: #000;
}

.user-address-history {
  display: flex;
}
.user-address-history__title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 640px) {
  .user-address-history__title {
    font-size: 22px;
  }
}
.user-address-history-bg {
  width: 100%;
  height: calc(100% + 28px);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.user-address-history-wrap {
  width: 1000px;
  z-index: 2;
  margin-top: 5rem;
  max-height: 450px;
  height: calc(100vh - 8rem);
  position: fixed;
  background-color: #fff;
  left: calc(50% - 500px);
}
@media (max-width: 1050px) {
  .user-address-history-wrap {
    left: 40px;
    width: calc(100% - 80px);
  }
}
.user-address-history-wrap-inner {
  overflow: auto;
  max-height: 450px;
  min-height: 200px;
  padding-bottom: 20px;
  background-color: #fff;
}
.user-address-history-close {
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
}

.user-delete {
  display: flex;
}
.user-delete__title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
@media (min-width: 640px) {
  .user-delete__title {
    font-size: 22px;
  }
}
.user-delete__content {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
}
.user-delete__content-button {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.user-delete__content-yes {
  color: #FF0000;
}
.user-delete-bg {
  width: 100%;
  height: calc(100% + 28px);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.user-delete-wrap {
  width: 300px;
  z-index: 2;
  margin-top: 5rem;
  position: fixed;
  background-color: #fff;
  left: calc(50% - 150px);
}
.user-delete-wrap img {
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
}

.user-change-password {
  display: flex;
}
.user-change-password__title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 640px) {
  .user-change-password__title {
    font-size: 22px;
  }
}
.user-change-password-bg {
  width: 100%;
  height: calc(100% + 28px);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.user-change-password-wrap {
  width: 290px;
  z-index: 2;
  margin-top: 5rem;
  position: fixed;
  background-color: #fff;
  left: calc(50% - 150px);
}
.user-change-password-wrap img {
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
}
.user-change-password__block-form {
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.user-change-password__block-form-field {
  margin-bottom: 20px;
}
.user-change-password__block-form-field input {
  max-width: 250px;
}
.user-change-password__block-form-field .input-error {
  border-color: #FF0000;
}
.user-change-password__block-form-field .error-message {
  color: #FF0000;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 12px;
}
.user-change-password__block-form button {
  margin-left: auto;
  margin-right: auto;
}
.user-change-password__block .success-message {
  color: #C6E870;
  margin-bottom: 10px;
}

.collection-names h2, .collection-names h3 {
  text-align: center;
  font-weight: normal;
  font-size: 46px;
  line-height: 54px;
  padding-bottom: 60px;
}
@media (max-width: 639px) {
  .collection-names h2, .collection-names h3 {
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 30px;
  }
}
.collection-names h3 {
  padding-bottom: 0;
  padding-top: 15px;
}
.collection-names .user-profile-line {
  gap: 9px;
}
@media (max-width: 639px) {
  .collection-names .user-profile-line {
    display: block;
    margin-left: auto;
    max-width: 300px;
    margin-right: auto;
  }
}
.collection-names .user-profile-line label {
  width: auto;
  text-align: left;
  font-size: 16px;
  font-family: "Proxima Nova", sans-serif;
}
@media (max-width: 639px) {
  .collection-names .user-profile-line label {
    margin-bottom: 10px;
  }
}
.collection-names-input {
  font-size: 16px;
  font-family: "Proxima Nova Light", sans-serif;
}
@media (max-width: 639px) {
  .collection-names-input {
    margin-bottom: 10px;
  }
}
.collection-names-input-error, .collection-names-input-success {
  color: #C42905;
  max-width: 350px;
  font-family: "Proxima Nova Light", sans-serif;
  font-size: 14px;
  position: absolute;
}
@media (max-width: 991px) {
  .collection-names-input-error, .collection-names-input-success {
    max-width: 250px;
  }
}
@media (max-width: 639px) {
  .collection-names-input-error, .collection-names-input-success {
    position: relative;
    margin-top: -5px;
    margin-bottom: 5px;
  }
}
.collection-names-input-success {
  color: green;
}
.collection-names-input.error {
  border-color: #C42905;
}
.collection-names-input.success {
  border-color: green;
}
.collection-names-buttons .button {
  margin-top: 0;
  text-decoration: none;
  border-radius: 4px;
  padding-left: 31px;
  width: auto;
  padding-right: 31px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.collection-names-table {
  background-color: #fff;
  padding-top: 26px;
  padding-left: 18px;
  padding-right: 20px;
  padding-bottom: 40px;
  margin-top: 47px;
}
.collection-names-table-head-left {
  padding-left: 25px;
  padding-bottom: 13px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 22px;
}
.collection-names-table-head p {
  padding-bottom: 20px;
}
.collection-names-table table tr th:last-child {
  text-align: right;
  padding-right: 12px;
}
.collection-names-table table tr td button {
  text-decoration: underline;
  cursor: pointer;
}
.collection-names-table table tr td button:hover {
  text-decoration: none;
}
.collection-names-table table tr td:first-child {
  max-width: 350px;
  padding-right: 15px;
}
.collection-names-table table tr td:last-child {
  text-align: right;
  padding-right: 12px;
}
.collection-names-table p {
  padding-left: 25px;
}
.collection-names .remove-collection-window {
  width: 350px;
  height: 250px;
  position: fixed;
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  margin-top: 0;
  outline: 1px solid #D0D0D0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  top: 30%;
  padding-left: 20px;
  padding-right: 20px;
}
.collection-names .remove-collection-window__title {
  width: 100%;
  text-align: center;
  font-size: 22px;
}
.collection-names .remove-collection-window__text {
  margin-bottom: 20px;
}
.collection-names .remove-collection-window__buttons button {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.collection-names-back-buttons .button {
  width: 170px;
}

.rb-confirmation-collection-wrap {
  padding-left: 20px;
  padding-right: 20px;
}
.rb-confirmation-collection-wrap .rb-loading__title {
  margin-bottom: 5px;
}
.rb-confirmation-collection-wrap .rb-loading__title-collection-name {
  text-align: left;
  font-size: 15px;
  font-family: "Proxima Nova Light", sans-serif;
}
.rb-confirmation-collection-wrap .rb-confirmation__title {
  margin-top: 30px;
}
.rb-confirmation-collection-wrap .rb-confirmation__buttons {
  margin-top: 15px;
  width: 130px;
}
.rb-confirmation-collection__text {
  text-align: left;
}