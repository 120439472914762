.user-address-history {
  display: flex;

  &__title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    padding-bottom: 20px;
    @media (min-width: $mobile_min) {
      font-size: 22px;
    }
  }

  &-bg {
    width: 100%;
    height: calc(100% + 28px);
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &-wrap {
    width: 1000px;

    z-index: 2;

    margin-top: 5rem;
    max-height: 450px;

    height: calc(100vh - 8rem);
    position: fixed;
    background-color: #fff;
    left: calc(50% - 500px);


    @media (max-width: $big-tablet) {
      left: 40px;
      width: calc(100% - 80px);
    }

    &-inner {
      overflow: auto;
      max-height: 450px;
      min-height: 200px;
      padding-bottom: 20px;
      background-color: #fff;
    }


  }
  &-close {
    position: absolute;
    right: -30px;
    top: -30px;
    cursor: pointer;
  }
}
