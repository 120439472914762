.rb-details-table {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  min-height: 278px;
  background-color: #fff;
  max-width: 100%;

  @media (min-width: $mobile_min) {
    padding-left: 21px;
    padding-right: 21px;
    width: 580px;
    border: none !important;
    min-height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-top: 2rem;
  }

  &__item {
    display: flex;
    font: normal normal normal 16px/20px $font-light;
    color: #707070;

    &__header {
      margin-bottom: 7px;
      font-family: $font-light;
      font-size: 14px;
      line-height: 17px;

      .download-pdf {
        cursor: pointer;
        font-family: $font-bold;
        margin-top: 10px;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      a {
        color: #707070;
        text-decoration: underline;
        font-size: 14px;
        line-height: 17px;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        width: 15px;
        height: 15px;
      }
    }

    &__value, &__value a {
      margin-left: auto;
      color: $rb-secondary;
      font: normal normal normal 14px/17px $font-light;
      min-width: 114px;
    }
    &__value {
      text-align: right;
    }

    &__value a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    img {
      margin-left: 10px;
      width: 15px;
      height: 15px;
    }

    img.clicked {
      animation: animate_copy 300ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &__header {

    font: normal normal normal 16px/20px $font-bold;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #707070;
    @media (min-width: $mobile_min) {
      margin-top: 48px;

    }

    span {
      position: relative;

      .help__question-answer {

        right: -256px;
        left: auto;
        top: -16px;

        &:before {

          left: -9px;
          top: 25px;
          transform: rotate(-90deg);
        }

        &:after {

          left: -7px;
          top: 25px;
          transform: rotate(-90deg);
        }
      }

    }

    .rb-details-table__header {

    }
  }

  &__copy {
    cursor: pointer;
  }

  &__dotted-space {
    margin-left: 18px;
    margin-right: 9px;
    height: 50%;
    flex-grow: 1;
    background-image: linear-gradient(
        to right,
        $rb-info 33%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    span {
      background-color: #fff;
      padding-right: 6px;
      font: normal normal normal 14px/17px $font-light, sans-serif;
      top: -5px;
      position: relative;
    }
  }


  &-not-found-text {
    margin-top: 20px;
    font-family: $font-light;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
  }
}

@keyframes animate_copy {
  100% {
    transform: scale(1.15);
  }
}
