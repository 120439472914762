.user-change-password {
  display: flex;

  &__title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: $mobile_min) {
      font-size: 22px;
    }
  }


  &-bg {
    width: 100%;
    height: calc(100% + 28px);
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &-wrap {
    width: 290px;
    z-index: 2;
    margin-top: 5rem;
    position: fixed;
    background-color: #fff;
    left: calc(50% - 150px);


    img {
      position: absolute;
      right: -30px;
      top: -30px;
      cursor: pointer;
    }
  }

  &__block {
    &-form {
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 20px;

      &-field {
        margin-bottom: 20px;

        label {

        }

        input {
          max-width: 250px;
        }
        .input-error {
          border-color: #FF0000;
        }
        .error-message {
          color: #FF0000;
          font-family: $font-light;
          font-size: 12px;
        }
      }
      button {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .success-message {
      color: #C6E870;
      margin-bottom: 10px;
    }
  }
}
