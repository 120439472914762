.help {
  &__trigger {
    position: fixed;
    right: 10px;
    bottom: 8px;
    width: 107px;
    height: 89px;
    transition: bottom 0.5s ease;
    @media (min-width: $mobile_min) {
      bottom: 8px;
    }
    @media (max-width: $mobile_max) {
      display: none;
    }

    &-text {
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 7px;
      font-family: $font-light;

      span {
        font-family: $font-medium;
      }

    }

    svg {
      rect {

        transform: translate(1377px, 1049px);
        fill: #dedede;
      }

    }
  }

  &__video {
    position: absolute;
    right: 0;
    top: 102px;
    margin-right: 20px;
    border: 1px solid #C6E870;
    padding: 6px;
    text-align: left;
    width: 36px;
    height: 36px;
    transition: width 0.5s ease, height 0.5s ease, padding 0.5s ease, background-color 0.5s ease;
    @media (max-width: 730px) {
      top: 159px;
      margin-right: 10px;
    }
    @media (max-width: $mobile_max) {
      display: none;
    }

    &-icon {
      cursor: pointer;
      opacity: 0.6;
      width: 23px;
      height: 23px;
      transition: width 0.5s ease, height 0.5s ease;
    }

    &-close {
      opacity: 0;
      position: absolute;
      z-index: -1;
      right: 9px;
      top: 8px;
      cursor: pointer;
      transition: z-index 0.5s ease, opacity 0.5s ease;
    }

    &-text {
      opacity: 0;
      white-space: nowrap;
      padding-top: 10px;
      overflow: hidden;
      width: 0%;
    }

    &-link {
      opacity: 0;
      white-space: nowrap;

      width: 0%;
      display: block;
      overflow: hidden;
    }
  }

  &__video.active {
    background-color: #EDF0E5;
    width: 215px;
    height: 109px;
    padding: 16px 9px 12px 16px;

    @media (max-width: 920px) {
      width: 115px;
      height: 128px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 40%;
      left: -13px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 11px solid #C6E870;
      transform: rotate(-90deg);
    }

    &:after {
      content: "";
      position: absolute;
      top: 40%;
      left: -11px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 11px solid #EDF0E5;
      transform: rotate(-90deg);
    }

    .help__video-icon {
      width: 31px;
      height: 31px;
      cursor: default;
      opacity: 1;

    }

    .help__video-close {
      opacity: 1;
      z-index: 0;
    }

    .help__video-text {
      opacity: 1;
      width: 100%;
      color: #000;
      font-size: 14px;
      line-height: 18px;
      font-family: $font-light;
    }

    .help__video-link {
      opacity: 1;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      font-family: $font-light;
      color: #000;
      @media (max-width: 920px) {
        display: none;
      }
      a {

        text-decoration: underline;
        padding-right: 5px;
        padding-left: 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &-small {
        display: none;

        text-decoration: underline;
        @media (max-width: 920px) {
          display: block;
        }
        &:hover {
          text-decoration: none;
        }
      }

    }
  }

  &__question {
    position: absolute;
    right: -32px;
    top: calc(50% - 12px);
    @media (max-width: $mobile_max) {
      display: none;
    }

    &-mark {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid rgba(112, 112, 112, 0.2);
      background-color: #fff;
      font-size: 16px;
      line-height: 15px;
      font-family: $font-medium;
      justify-content: center;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        position: relative;
        top: 1px;
        color: #1B2F28;
      }
    }

    &-answer {
      background-color: #fff;
      width: 250px;
      padding: 8px 10px 9px 8px;
      position: absolute;
      right: -256px;
      top: 2px;
      border: 1px solid #C6E970;
      box-shadow: 2px 4px 6px #00000029;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.5s, z-index 0.5s ease;
      text-align: left;

      &:before {
        content: "";
        position: absolute;
        top: 5%;
        left: -9px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid #C6E970;
        transform: rotate(-90deg);
      }

      &:after {
        content: "";
        position: absolute;
        top: 5%;
        left: -7px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid #fff;
        transform: rotate(-90deg);
      }

      p, a {
        font-size: 14px;
        line-height: 15px;
        font-family: $font-medium;

      }

      p {
        padding-bottom: 4px;
      }

      a {
        color: #707070;
        border-bottom: 1px solid #000;

        &:hover {
          border: none;
        }
      }

    }

    @media (max-width: 940px) {
      &-answer {
        left: -256px;
        right: auto;

        &:before {
          left: auto;
          right: -9px;
          transform: rotate(90deg);
        }

        &:after {
          left: auto;
          right: -7px;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__question.top-right {
    top: -33px;
    right: 0;

    .help__question-answer {
      left: -256px;
      right: auto;

      &:before {
        left: auto;
        right: -9px;
        transform: rotate(90deg);
      }

      &:after {
        left: auto;
        right: -7px;
        transform: rotate(90deg);
      }
    }
  }

  &__question.active {
    .help__question-mark {

      border-color: #fff;
      background-color: #CEE88C;

      span {
        position: relative;
        top: 1px;
        color: #fff;
      }
    }

    .help__question-answer {
      z-index: 10;
      opacity: 1;
    }
  }
}

.rb-info:not(.rb-flex-wrap-wrap) {
  @media (max-width: 767px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: $mobile_max) {
    padding-left: 28px;
    padding-right: 28px;
  }

  .help__question {
    right: -5px;
    @media (max-width: 767px) {
      right: 20px;
    }
    @media (max-width: 1250px) {
      .help__question-answer {
        left: -256px;
        right: auto;

        &:before {
          left: auto;
          right: -9px;
          transform: rotate(90deg);
        }

        &:after {
          left: auto;
          right: -7px;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.rb-info.rb-flex-wrap-wrap {
  max-width: 350px;

  .help__question {
    top: 42px;
    right: -5px;

  }
}

//.rb-info-is-notauthenticated {
//  .help__question {
//    top: calc(50% - 16px);
//
//  }
//}

.help__trigger.active {
  svg {
    rect {
      transform: translate(1400px, 1049px);
      fill: #c6e870;
      transition: transform 0.3s ease, fill 0.3s ease;
      cursor: pointer;
    }
  }

}

.help__trigger.not-authenticated {
  bottom: 48px;
  @media (min-width: $mobile_min) {
    bottom: 43px;
  }
}



.help__trigger.near-bottom {
  transition: none;
  margin-bottom: 80px;
  position: absolute;
  @media (min-width: $mobile_min) {
    margin-bottom: 0;
  }
}

.rb-btn-help-wrap {
  max-width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
