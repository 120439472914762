.footer {
  background-color: #1B3028;
  color: $color-white;
  &__container {
    display: block;
    position: relative;
    padding: 20px 20px 12px 20px;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1148px;
  }
  &-top {
    padding-top: 45px;
    padding-bottom: 55.5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media (max-width: $tablet) {
      padding-top: 35px;
      padding-bottom: 35px;
    }
    @media (max-width: $mobile) {
      justify-content: center;
    }

    &-left {
      padding-right: 20px;
      @media (max-width: $big-tablet) {
        padding-right: 10px;
      }
      @media (max-width: $mobile) {
        padding-right: 0;
      }

      &-title {
        font-family: $font2;
        color: $color-white;
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 8px;
      }

      &-input {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: no-repeat;
        border-radius: 4px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: $color-white;
        padding-left: 9px;
        padding-right: 9px;
        max-width: 284px;
        outline: none;
        font-size: 14px;
        line-height: 17px;
        font-family: $font-light;
        box-sizing: border-box;
        height: 32px;
        @media (max-width: $big-tablet-footer) {
          max-width: 196px;
        }


        &-error {
          color: #dc3232;
          font-size: 14px;
          font-weight: normal;
          display: block;
          position: absolute;
        }

        &-success {
          color: green;
          font-size: 14px;
          font-weight: normal;
          display: block;
          position: absolute;
        }
      }


      &-submit {
        padding: 7px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        line-height: 20px;
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        svg {
          fill: #88938f;
          padding-left: 0;
          font-style: normal;
        }

        &:hover {
          background-color: $color-white;

          svg {
            fill: $color-black;
          }
        }
      }

      form {
        display: flex;
      }
    }

    &-center {
      align-self: flex-end;
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: $big-tablet) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (max-width: $mobile) {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 60px);
        text-align: left;
      }

      &-title {
        font-size: 18px;
        line-height: 21px;
        font-family: $font2;
        color: $color-white;
        padding-bottom: 8px;

        @media (max-width: $mobile) {
          text-align: left;
          max-width: 230px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-products {
        background-color: #88938F;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 14px;
        line-height: 17px;
        font-family: $font-light-italic;
        padding-top: 6px;
        padding-left: 9px;
        padding-right: 9px;
        padding-bottom: 7px;
        width: 203px;
        cursor: pointer;
        position: relative;
        z-index: 2;

        @media (max-width: $mobile) {
          margin-left: auto;
          margin-right: auto;
          width: 197px;
          left: -15px;
        }

        &:before {
          position: absolute;
          background-color: #88938F;
          right: -33px;
          top: -1px;
          width: 32px;
          height: 32px;
          content: "";
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-left: 0;

          border-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:after {
          position: absolute;
          background-image: url(/static/public/images/svg/products_arrow.svg);
          right: -22px;
          top: 11px;
          width: 10px;
          height: 8px;
          content: "";
        }

        ul {
          list-style-type: none;
          height: 0;
          overflow: hidden;
          transition: height 0.2s ease;
          position: absolute;
          background-color: #88938F;
          border-radius: 4px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          width: calc(100% + 34px);
          left: -1px;
          padding-left: 8px;
          padding-right: 8px;
          top: 100%;

          li {
            display: flex;
            margin-top: 5px;
            padding-bottom: 2px;

            a {
              color: #fff;
              font-family: $font-regular;
              padding-left: 0;
              padding-right: 0;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &:hover {
          border-bottom-left-radius: 0;
          border-right-color: #88938F;

          &:before {
            border-bottom-right-radius: 0;
            border-left-color: #88938F;

          }
          &:after {
            transform: rotate(180deg);
          }
          ul {
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-top: 0;
          }
        }
      }

      a {
        padding-left: 9.65px;
        padding-right: 9.65px;
        text-decoration: none;
        @media (max-width: $big-tablet) {
          padding-left: 7.65px;
          padding-right: 7.65px;
        }

        span {
          position: relative;
          background-color: #fff;
          display: inline-block;
          border-radius: 50%;
          width: 30px;
          height: 30px;

          svg {
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.1);
            left: -2px;
            position: relative;
            top: -2px;
            fill: #1B3028;

            &:hover {
              fill-opacity: 0.9;
            }
          }

        }
      }
    }

    &-right {
      padding-left: 20px;
      @media (max-width: $big-tablet) {
        padding-left: 10px;
      }
      @media (max-width: $tablet) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding-left: 0;
      }

      &-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          a {
            font-family: $font6;
            line-height: 20px;
            text-decoration: none;
            color: $color-white;
            margin-left: 32px;
            @media (max-width: $mobile) {
              margin-left: 0;
              margin-right: 8px;
            }

            &:hover {
              border-bottom: 1px solid;
            }
          }
        }
      }
    }
  }

  &-center {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media (max-width: $mobile) {
      justify-content: center;
    }

    &-left {
      @media (max-width: $mobile) {
        justify-content: center;
        display: flex;
        width: 100%;
      }

      &-social {
        display: block;
        padding-left: 58px;
        @media (max-width: $mobile) {
          padding-left: 10px;
        }
        a {
          padding-left: 9.65px;
          padding-right: 9.65px;
          text-decoration: none;

          span {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 30px;

            span {
              position: absolute;
              background-color: #fff;
              left: 0;
              z-index: 0;
              top: 0;
              width: calc(100% - 4px);
              height: calc(100% - 4px);
              border-radius: 50%;
            }

            svg {
              border-radius: 50%;
              border: 1px solid rgba(255, 255, 255, 0.1);
              left: -2px;
              position: relative;
              top: -2px;
              fill: #1B3028;
              z-index: 1;

              &:hover {
                fill-opacity: 0.9;
              }
            }
          }
        }
      }


    }

    ul {
      display: flex;
      list-style-type: none;
      @media (max-width: $mobile) {
        padding-left: 0;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      li {
        position: relative;
        padding-left: 20px;
        @media (max-width: $mobile) {
          padding-left: 0;
          padding-right: 20px;
        }

        &:after {
          content: "|";
          color: #fff;
          position: relative;
          margin-left: 15px;
          top: 2px;
        }

        a {
          font-size: 14px;
          line-height: 16px;
          font-family: $font2;
          text-decoration: none;
          color: $color-white;

          &:hover {
            text-decoration: underline;
          }
        }

      }

      li:last-child {
        &:after {
          content: none;
        }

        @media (max-width: $mobile) {

          padding-right: 0;
        }
      }
    }

  }

  &-bottom {
    font-size: 14px;
    line-height: 16px;
    font-family: $font2;
    padding-bottom: 20px;
    padding-top: 20px;
    @media (max-width: $mobile) {
      justify-content: center;
    }
  }
}

.grecaptcha-badge {
  @media (max-width: $mobile) {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}
