.rb-hidden {
  display: none !important;
}
.rb-column {
  width: 60%;
}
.rb-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rb-justify-center {
  justify-items: center;
}
.rb-self-center {
  justify-self: center;
}
.rb-self-stretch {
  justify-self: stretch;
}
.rb-text-center {
  text-align: center;
}
.rb-text-left {
  text-align: left;
}
.rb-mt-2xl {
  margin-top: 40px;
}
.rb-mt-normal {
  margin-top: 2rem;
}
.rb-mt-sm {
  margin-top: 0.75rem;
}
.rb-mt-xs {
  align-self: center;
  margin-top: 6px;
  font: normal normal normal 16px/20px $font-regular;
}
.rb-position-absolute {
  position: absolute;
}
.rb-position-relative {
  position: relative;
}
.rb-display-flex {
  display: flex;
}
.rb-justify-content-space-between {
  justify-content: space-between;
}
.rb-justify-content-center {
  justify-content: center;
}
.rb-justify-content-flex-end {
  justify-content: flex-end;
}
.rb-justify-content-flex-start {
  justify-content: flex-start;
}
.rb-flex-wrap-wrap {
  flex-wrap: wrap;
}
.rb-flex-direction-column {
  flex-direction: column;
}
.rb-align-items-center {
  align-items: center;
}
.rb-align-items-flex-start {
  align-items: flex-start;
}
.rb-px-xl {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.rb-grow-0 {
  flex-grow: 0;
}
.w-100 {
  width: 100%;
}
.pr-20 {
  padding-right: 20px;
}
.mb-32 {
  margin-bottom: 32px;
}

