.blocks-reviews, .customer__reviews {
  padding-top: 70px;
  padding-bottom: 64px;
  border-bottom: 1px solid #EDF0E5;
  @media (max-width: $mobile-max) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-title {
    font-size: 36px;
    line-height: 42px;
    font-family: $font-regular-secondary;
    color: #000;
  }

  .reviews {
    margin-top: 82px;
    padding-bottom: 20px;
    @media (max-width: $mobile-max) {
      margin-top: 20px;
    }

    &-item {
      flex: 0 0 auto;
      width: 100%;

      p {
        font-size: 22px;
        line-height: 32px;
        font-family: $font-light;
        margin-top: 0;
        margin-bottom: 19px;
        color: #000;
        @media (max-width: $mobile-max) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      &-author {
        padding-top: 19px;

        &-photo {
          img {
            border-radius: 50%;
            margin-right: 12px;
          }
        }

        &-name {
          color: #333333;
          line-height: 20px;
          font-family: $font-bold;
          font-size: 16px;

          span {
            display: block;
            font-family: $font-light;
            font-size: 14px;
            line-height: 23px;
            color: #707070;
            padding-top: 6px;
          }
        }
      }
    }

    &-wrap {
      max-width: calc(76.9% - 40px);

      overflow: hidden;
      @media (max-width: $mobile-max) {
        max-width: calc(100% - 60px);
      }
    }
  }

  &-slider {
    &-slides {


    }
  }

  &-nav {
    z-index: 1;

    &-button {
      @media (max-width: $mobile-max) {
        width: 30px;
      }
      img {
        cursor: pointer;
      }

      &:hover {
        img {
          opacity: 0.4;
        }
      }
    }

    &-prev {
      margin-left: 10px;
      margin-right: 6px;
      transform: rotate(180deg);
      @media (max-width: $mobile-max) {
        margin-left: 0;
      }
    }

    &-next {
      margin-right: 10px;
      @media (max-width: $mobile-max) {
        margin-right: 0;
      }
    }
  }
}
