// Responsive
$mobile_min: 640px;
$mobile_max: 639px;
$tablet_max: 991px;
$tablet: 991px;
$height_max: 1010px;

// Mobile
$mobile: 767px;

// Big Tablet
$big-tablet: 1050px;
$big-tablet-footer: 1119px;
$small-laptop: 1169px;
// Colors
$rb-primary: #1B2F28;
$rb-secondary: #6f6f6f;
$rb-light: #f8f9fa;
$rb-info: #a2a2a2;

$color-white: #ffffff;
$color-black: #000000;

// Fonts
$font-regular: 'Proxima Nova', sans-serif;
$font-regular-secondary: 'Arial, Helvetica', sans-serif;
$font-light: 'Proxima Nova Light', sans-serif;
$font-light-italic: 'Proxima Nova Light Italic', sans-serif;
$font-bold: 'Proxima Nova Semibold', sans-serif;
$font-medium: 'Proxima Nova Medium', sans-serif;

$font2: 'Arial', sans-serif;
$font6: 'Proxima Nova Light', sans-serif;
