.rb-input {
  border: 1px solid #70707080;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  max-width: 28.875rem;
  width: 350px;
  height: 42px;

  &:placeholder-shown {
    font-style: italic;
  }

  &.invalid {
    border: 1px solid red;
  }

  &-wrap {
    margin-bottom: 20px;
    position: relative;

    .btn-show-hide {
      position: absolute;
      padding-right: 12px;
      top: 25px;
      border-left: 1px solid rgba(112, 112, 112, 0.5019607843);
      padding-left: 12px;
      height: 40px;
      right: 0;
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      i {
        top: 2px;
        position: relative;
      }

      &:hover {
        background-color: #1B3028;

        i {
          color: #fff;
        }
      }
    }

    &-key {
      input {
        padding-right: 50px;
      }
    }
  }

  &-label {
    display: block;
    line-height: 19px;
    margin-bottom: 5px;
  }

  &-error {
    border-color: red;

    &-text {
      display: block;
      margin-top: 5px;
      color: red;
      font-size: 14px;
    }

    &-list {
      list-style-type: none;
      color: red;
      font-size: 14px;
      margin-bottom: 10px;

      li {
        margin-bottom: 10px;
      }
    }
  }

}

.rb-input-wrap-key {
  .rb-input {

    width: 325px;
    @media (max-width: $mobile) {
      width: calc(100% - 25px);
    }
  }

  .btn-show-hide {

    right: 25px;

  }

  img {
    position: absolute;
    top: calc(50% + 3.5px);
    right: 0;
    cursor: pointer;
  }
}

/* Custom radio button */
.rb-radio-button {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 20px;
  user-select: none;
  margin-bottom: 20px;
  input[type="radio"] {
    display: none;
  }



  label {
    cursor: pointer;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    sup {
      position: absolute;
      right: -30px;
      font-size: 14px;
      top: -47px;
      background-color: #C6E870;
      padding: 4px 9px;
      color: #000;
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: "";
        height: 0;
        position: absolute;
        bottom: -7px;
        transform: rotate(180deg);
        width: 0;
        border-bottom: 7px solid #c6e970;
        left: 16px;
      }
    }
  }
}
