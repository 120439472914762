.rb-root {
  display: flex;
  flex-direction: column;

  width: 100%;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  height: 100%;
}

.full-screen-min-height {
  min-height: 100svh;
}

.rb-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  position: relative;
  width: 100%;
  height: 100%;

  @media (min-width: $mobile_min) {
    padding-top: 0;
  }

  .rb-area {
    border: 1px dashed #70707080;
  }
}

.rb-header {
  font: normal normal normal 26px/31px $font-regular-secondary;
  text-align: center;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 400;
  @media (min-width: $mobile_min) {
    font: normal normal normal 46px/54px $font-regular-secondary;
    margin-bottom: 12px;
    margin-top: 102px;
  }

  &-wrap {
    max-width: 1004px;
    width: 100%;
    text-align: center;
    position: relative;
  }

  &-stamp {
    margin-top: 0;
  }
}

.rb-subheader {
  font: normal normal normal 16px/20px $font-regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 308px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $mobile_min) {
    font: normal normal normal 18px/22px $font-regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 508px;
    height: 22px;
  }
}

.rb-method-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.rb-btns-select {
  display: flex;
  flex-grow: 0;
  padding: 0.375rem;
  align-self: center;
  border-radius: 12px;
  position: relative;

  &-disabled {

    button {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.rb-method-file__screen, .rb-method-hash__screen, .rb-method-csv__screen {
  width: 100%;
}

.rb-method-hash__screen {
  .hash-screen-container p {
    @media (min-width: $mobile_min) {
      padding-top: 73px;
    }
  }
}

.rb-method {
  align-content: center;
  padding-top: 28px;
  width: 100%;
  box-sizing: unset;
}

.rb-area {
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 13.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #70707033;
  background-color: $rb-light;
  transition: transform 135ms ease-out;
  margin: auto;
  width: calc(100% - 40px);
  max-width: 390px;
  height: 278px;
  @media (min-width: $mobile_min) {
    max-width: 964px;
    height: 284px;
    border: 1px dashed #70707080;
  }

  &-disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__text {
    display: none;
    @media (min-width: $mobile_min) {
      display: block;
      font: normal normal 300 16px/20px $font-light;
      color: #707070;
    }
  }

  &-no-hash {
    gap: 2em;
  }
}

.rb-area.dragging {
  transform: scale(1.05);
}

.rb-container {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $mobile_min) {
    width: calc(100% - 40px);
    max-width: 964px;
  }
}

.rb-hash-text-wrap {
  @media (max-width: $mobile_max) {
    background-color: #fff;
    padding-left: 28px;
    padding-right: 27px;
    padding-top: 39px;
    padding-bottom: 20px;
  }
}

.rb-hash-input {
  position: relative;

  &-verify {
    width: 100%;
    max-width: 335px;

    p {
      text-align: left;
    }
  }

  &-verify {
    max-width: 350px;

  }

  input {
    width: 100%;
    max-width: 100%;
    cursor: default;
  }

  .rb-btn-disabled {
    background-color: rgba(255, 255, 255, 0.3);
  }

  img {
    position: absolute;
    right: 10px;
    top: calc(50% - 11px);
    cursor: pointer;
  }
}

.rb-method-hash-text {
  font-size: 16px;
  line-height: 20px;

  a {
    text-decoration: underline;
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }

  &-wrap {
    margin-top: 30px;
    justify-content: center;
    @media (max-width: $mobile_max) {
      align-items: flex-start;
      max-width: 285px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      margin-right: 7.5px;
      opacity: 0;
      @media (min-width: $mobile_min) {
        display: none;
      }
    }

    &-first {
      margin-bottom: 5px;

      img {
        opacity: 1;
        @media (min-width: $mobile_min) {
          display: block;
        }
      }
    }

    &-last {
      margin-bottom: 12px;
    }
  }
}

.stamp-bulk__text {
  border: 1px solid #C6E970;

  background-color: #EDF0E5;
  font-family: $font-light;
  margin-top: 31px;
  width: 100%;
  margin-bottom: -28px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  padding-top: 13px;
  padding-bottom: 12px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: calc(100% - 40px);
  @media (min-width: $mobile_min) {
    width: auto;
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 22px;
    padding: 10px 34px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: $mobile_max) {
    font-family: $font-bold;
  }

  a {
    text-decoration: underline;
    @media (max-width: $mobile_max) {
      display: block;
      margin-top: 5px;
      font-family: $font-light;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.hash-screen-container {
  padding: 0 20px 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 13.5rem;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2px;
  background-color: $rb-light;
  transition: transform 135ms ease-out;
  margin: auto;
  height: 278px;
  border: 1px dashed #70707080;

  p {
    padding-top: 39px;
    padding-bottom: 5px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.csv-screen-container {
  height: auto;
}
.rb-info {
  padding: 28px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  justify-content: center;
  font-size: 16px;
  max-width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  button {
    margin-bottom: 10px;
  }

  span {
    color: #333333;
    font-family: $font-light;
  }

  a {
    text-decoration: underline;
    margin-right: 5px;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    margin-right: 10px;
  }

  .rb-stamper-login {
    font-size: 14px;
  }
}

.rb-hash {
  bottom: 13px;
  left: 15px;
  font-size: 16px;
  color: #707070;
  font-family: $font-light;
  padding-right: 30px;

  &__text {
    white-space: nowrap;
    padding-right: 0.3rem;
  }

  &__content {
    word-break: break-all;
    text-align: left;

    img {
      margin-left: 7px;
      margin-top: -2px;
    }
  }
}

.rb-file-loaded {
  margin-bottom: 20px;

  span {
    font-size: 18px;
    padding-left: 10px;
  }
}

.rb-invalid-text {
  margin-top: 5px;
  position: absolute;
  color: red;
}
