@font-face {
  font-family: "Proxima Nova";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Regular.otf");
}
@font-face {
  font-family: "Proxima Nova Light";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Light.otf");
}
@font-face {
  font-family: "Proxima Nova Light Italic";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Light_Italic.otf");
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Semibold.otf");
}
@font-face {
  font-family: "Proxima Nova Medium";
  src: url("/static/public/fonts/proxima-nova-2/Mark_Simonson_Proxima_Nova_Medium.otf");
}

