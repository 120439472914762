.rb-btn {
  display: flex;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  border-radius: 6px;
  cursor: pointer;
  color: $rb-secondary;
  background-color: $rb-light;

  @media (min-width: $mobile_min) {
    font-size: 1rem;
    line-height: 1.25rem;
  }



  &-svg {
    fill: #1a1818;
    margin-right: 8px;
  }

  &-selected &-svg {
    fill: #fff;
  }



  &-file, &-hash, &-user-found {
    display: table-cell;
    vertical-align: middle;
    width: 160px;

    @media (max-width: $mobile_max) {
      width: 140px;
    }
  }
  &-file {
    border-radius: 20px 1px 1px 21px;

  }
  &-hash {
    border-radius: 1px 20px 21px 1px;

  }


  &-selected {
    color: white;
    background-color: $rb-primary;
  }

  &-click {
    background-color: $rb-primary;
    color: #fff;
    border-radius: 4px;
    width: 214px;
    height: 62px;
    justify-content: center;
    @media (min-width: $mobile_min) {
      font-size: 1rem;
    }

    &-file {
      display: flex;
      align-items: center;
      padding: 0.8rem 1rem;
      gap: 8px;

      p {
        font-size: 14px;
        font-family: $font-regular-secondary;
      }
    }

    &__file-hash {
      background: none;
      color: #1B2F28;
      border-radius: 0;
      width: auto;
      height: auto;
      text-decoration: underline;
    }

    &__file-hash .rb-file-btn {
      padding: 0;
      gap: 0;
    }
  }

  &-verify {
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    border-radius: 4px;
    max-width: 28.875rem;
    width: 140px;
    height: 42px;
    justify-content: flex-start;
    padding-left: 40px;

    &--another {
      margin-top: 20px;
      height: 44px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      width: 285px;
      @media (min-width: $mobile_min) {
        height: 50px;
        margin-top: 33px;
        padding-top: 14px;
      }
    }

    .rb-btn-click.rb-btn-verify {
      padding-right: 29px;
      padding-top: 10px;
    }

  }

  &-user {
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    border-radius: 4px;
    max-width: 28.875rem;
    width: 210px;
    height: 42px;
    justify-content: flex-start;
    padding-left: 35px;

    .rb-btn-arrow {

      right: 36px;
    }

  }

  &-stamp {
    width: 277px;
    height: 42px;
    font-size: 16px;
    line-height: 20px;

    @media (min-width: $mobile_min) {
      width: 288px;
      height: 50px;
      font-size: 18px;
      line-height: 22px;
    }

    &-error {

      margin-bottom: 40px;
      padding-top: 16px;
      padding-bottom: 33px;
    }

    &-small {
      @media (max-width: $mobile_max) {
        padding-top: 21px;
      }
    }
  }

  &-disabled {
    cursor: default;
    background-color: #D0D0D0;
    color: #B1B1B1;

    img {
      opacity: 0.2;
      mix-blend-mode: difference;
      filter: grayscale(100%);
    }
  }

  &-stamp-window {
    margin-right: auto;
    margin-left: auto;
    margin-top: 21px;
    width: 287px;
    height: 42px;
    padding-top: 11px;
    font-size: 16px;

    @media (min-width: $mobile_min) {
      margin-top: 36px;
      width: 288px;
      height: 50px;
      padding-top: 16px;
      font-size: 18px;
    }
  }

  &-arrow {
    position: absolute;
    height: 10px;
    width: 10px;
    border-top: 3px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
    transform: rotate(135deg);
    margin-left: 50px;
    margin-top: 8px;
  }

  &-black {
    background-color: #1B3028;
    color: #fff;
    border: 1px solid #1B3028;

    &:hover, &:active, &:focus {
      background-color: #fff;
      color: #000;
    }
  }

  &-white {
    background-color: #fff;
    color: #000;
    border: 1px solid #1B3028;

    &:hover, &:active, &:focus {
      background-color: #1B3028;
      color: #fff;
    }
  }

  &-authentication {

    height: 50px;
    padding-top: 15px;
    margin-top: 40px;
    position: relative;

    width: 100%;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &-google {

    height: 50px;
    padding-top: 14px;
    margin-top: 20px;
    width: 100%;

    img {
      margin-right: 10px;
      margin-top: -5px;
      width: 25px;
    }
  }

  &-verified {
    margin-top: 20px;
    height: 42px;
  }

  &-header-plans {
    margin-right: 30px;
    border-radius: 4px;
    padding-top: 7px;
    padding-bottom: 7px;

    @media (max-width: $mobile_max) {
      margin-right: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      line-height: 28px;
      min-width: 102px;
    }
  }

}

.landing {
  &-buttons {
    margin-top: auto;

    .button {
      margin-top: 29px;
      background-color: #fff;
      font-size: 16px;
      line-height: 20px;
      color: #000;
      border: 1px solid #000;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 10px;
      padding-left: 29px;
      padding-right: 29px;

      svg {
        position: relative;
        margin-left: 12px;
        width: 7px;
      }

      &:hover {
        background-color: #000;
        color: #fff;

        svg {
          fill: #fff
        }
      }
    }
  }
}

// Preloader animation
.animate-spin {
  animation: spin 1s linear infinite
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rb-main-content-verify {
  .rb-btn-file, .rb-btn-hash, .rb-btn-user-found, .rb-btn-long-csv {
    width: 140px;
    @media (max-width: $mobile_max) {
      width: 111px;
    }
  }

  .rb-btn-long-csv {
    border-radius: 1px 20px 21px 1px;
justify-content: center;
  }
  .rb-btn-file {
    border-right: 1px solid #6f6f6f;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rb-btn-hash, .rb-btn-user-found  {
    border-radius: 0;
    border-right: 1px solid #6f6f6f;

  }
}
