.user-delete {
  display: flex;

  &__title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    @media (min-width: $mobile_min) {
      font-size: 22px;
    }
  }

  &__content {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 20px;

    &-button {
      font-family: $font-bold;
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
    }
    &-yes {
      color: #FF0000;
    }
  }

  &-bg {
    width: 100%;
    height: calc(100% + 28px);
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &-wrap {
    width: 300px;
    z-index: 2;
    margin-top: 5rem;
    position: fixed;
    background-color: #fff;
    left: calc(50% - 150px);

    img {
      position: absolute;
      right: -30px;
      top: -30px;
      cursor: pointer;
    }
  }
}
