.rb-body {
  font-family: $font-regular;
  display: flex;
  flex-direction: column;
  height: 100svh;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-regular-secondary;
}
.rb-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 150px;
  color: black;
  background: #EDEDED 0% 0% no-repeat padding-box;
position: relative;
  @media (min-width: $mobile_min) {
    width: 100%;
  }
  @media (max-width: $mobile_max) {
    padding-bottom: 100px;
  }
  @media (max-height: $height_max) {
   justify-content: center;
  }

}
.rb-text-normal {
  font: normal normal normal 14px/17px $font-light;
}


