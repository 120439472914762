.collection-names {
  h2, h3 {
    text-align: center;
    font-weight: normal;
    font-size: 46px;
    line-height: 54px;
    padding-bottom: 60px;

    @media (max-width: $mobile_max) {
      font-size: 30px;
      line-height: 38px;
      padding-bottom: 30px;
    }
  }

  h3 {

    padding-bottom: 0;
    padding-top: 15px;

  }

  .user-profile-line {
    gap: 9px;
    @media (max-width: $mobile_max) {
      display: block;
      margin-left: auto;
      max-width: 300px;
      margin-right: auto;
    }

    label {
      width: auto;
      text-align: left;
      font-size: 16px;
      font-family: $font-regular;
      @media (max-width: $mobile_max) {
        margin-bottom: 10px;
      }
    }

  }

  &-input {
    font-size: 16px;
    font-family: $font-light;
    @media (max-width: $mobile_max) {
      margin-bottom: 10px;
    }

    &-error, &-success {
      color: #C42905;
      max-width: 350px;
      font-family: $font-light;
      font-size: 14px;
      position: absolute;

      @media (max-width: $tablet) {
        max-width: 250px;

      }
      @media (max-width: $mobile_max) {
        position: relative;
        margin-top: -5px;
        margin-bottom: 5px;

      }
    }

    &-success {
      color: green;
    }
  }

  &-input.error {
    border-color: #C42905;
  }

  &-input.success {
    border-color: green;
  }

  &-buttons {
    .button {
      margin-top: 0;
      text-decoration: none;
      border-radius: 4px;
      padding-left: 31px;
      width: auto;
      padding-right: 31px;
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }

  &-table {
    background-color: #fff;
    padding-top: 26px;
    padding-left: 18px;
    padding-right: 20px;
    padding-bottom: 40px;
    margin-top: 47px;

    &-head {
      &-left {
        padding-left: 25px;
        padding-bottom: 13px;
        font-family: $font-bold;
        font-size: 22px;
      }

      p {
        padding-bottom: 20px;
      }
    }

    table {
      tr {
        th {
          &:last-child {
            text-align: right;
            padding-right: 12px;
          }
        }

        td {
          button {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              text-decoration: none;
            }
          }

          &:first-child {
            max-width: 350px;
            padding-right: 15px;
          }

          &:last-child {
            text-align: right;
            padding-right: 12px;
          }
        }
      }
    }

    p {
      padding-left: 25px;
    }
  }

  .remove-collection-window {

    width: 350px;
    height: 250px;
    position: fixed;
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    margin-top: 0;
    outline: 1px solid #D0D0D0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    top: 30%;
    padding-left: 20px;
    padding-right: 20px;

    &__title {
      width: 100%;
      text-align: center;
      font-size: 22px;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__buttons {
      button {
        font-family: $font-bold;
        font-size: 20px;
        margin: 10px;
        cursor: pointer;
        margin-top: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &-back-buttons {
    .button {
      width: 170px;
    }
  }
}

.rb-confirmation-collection {
  &-wrap {

    padding-left: 20px;
    padding-right: 20px;

    .rb-loading__title {
      margin-bottom: 5px;
      &-collection-name {
        text-align: left;
        font-size: 15px;
        font-family: $font-light;
      }
    }

    .rb-confirmation {
      &__title {
        margin-top: 30px;
      }

      &__buttons {
        margin-top: 15px;
        width: 130px;
      }
    }
  }

  &__text {
    text-align: left;
  }
}

