.reports {
  margin-top: 100px;

  &-page {
    padding-bottom: 51px;

    .rb-container {
      @media (min-width: $mobile_min) {
        max-width: 1148px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  h1 {
    font-size: 46px;
    line-height: 54px;
    font-weight: normal;
    margin-bottom: 10px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  h2 {
    font-size: 30px;
    line-height: 34px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 22px;
    line-height: 27px;
    font-family: $font-bold;
    margin-bottom: 24px;
    font-weight: normal;
  }

  &-text {
    max-width: 785px;
    margin-bottom: 50px;

    p {
      font-size: 18px;
      line-height: 22px;
      padding-top: 7px;
    }
  }

  &-content {
    border: 1px solid rgba(112, 112, 112, 0.2);
    background-color: #fff;
    padding: 36px 40px 98px 42px;
    position: relative;


    .html-table {
      margin: 0;

      svg {
        opacity: 0.6;
        margin-right: 15px;
      }
    }
  }

  &-all {
    text-align: right;
    margin-top: 20px;
    @media (max-width: $big-tablet) {
      text-align: left;
    }

    &-title {
      line-height: 22px;
      padding-bottom: 7px;
    }

    &-content {
      @media (max-width: $big-tablet) {
        justify-content: flex-start;
      }

      &-img {
        width: 29px;
        height: 29px;
        background-color: #EDF0E5;
        border-radius: 50%;
        margin-right: 8px;

        &-no {
          background-color: #1B2F28;
        }
      }
    }
  }

  &-head {
    margin-bottom: 60px;
    @media (max-width: $big-tablet) {
      display: block;
    }

    &-left {
      &-block {
        font-size: 18px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 10px;

        img {
          width: 24px;
          margin-left: 10px;
        }

        span {
          display: block;
          color: #000;
          margin-bottom: 7px;

        }

        b {
          font-family: $font-bold;
          font-size: 20px;
          line-height: 24px;
          color: #000;
          margin-right: 9px;
        }

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &-release {
          a {
            font-size: 20px;
          }
        }
      }
    }
  }

  &-blocks {
    width: 601px;
    @media (max-width: $mobile) {
      display: block;
      width: 100%;
    }
  }

  &-block {
    position: relative;
    width: 187px;
    height: 128px;
    padding-left: 20px;
    padding-top: 16px;
    padding-right: 35px;
    padding-bottom: 20px;
    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }

    b {
      font-size: 18px;
      line-height: 22px;
      font-weight: normal;
    }

    .help__question.top-right {
      top: 17.5px;
      right: 12px;
      color: #000;
    }

    .help__question-mark {
      width: 17.5px;
      height: 17.5px;

      span {
        top: 1px;
        font-size: 12px;
      }
    }

    &-number {
      position: absolute;
      bottom: 25px;
      left: 20px;
      font-size: 30px;
      line-height: 25px;
      font-family: $font-bold;
    }
  }

  &-table-head {
    margin-bottom: 15px;
    @media (max-width: $mobile) {
      display: block;
    }

    &-left {
      font-family: $font-bold;
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
      @media (max-width: $mobile) {
        padding-bottom: 15px;
      }
    }

    &-right {
      @media (max-width: $mobile) {
        display: flex;
        flex-wrap: wrap;
      }

      a {
        text-decoration: underline;
        line-height: 20px;
        padding-left: 30px;
        @media (max-width: $mobile) {
          padding-left: 0;
          padding-right: 30px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-producer {
    .reports-blocks {
      width: 394px;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    .reports-all {
      text-align: left;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-buttons {
    margin-top: 20px;

    .button {
      background-color: #000000;
      color: #ffffff;
      border: 1px solid transparent;
      width: 116px;

      svg {
        fill: #ffffff;
        transform: rotate(180deg);
        margin-right: 10px;

      }

      &:hover {
        border-color: #000000;
        color: #000000;
        background-color: #ffffff;

        svg {
          fill: #000000;
        }
      }
    }
  }
}

//User found page
.user-found {
  margin-top: 0;
  .reports-text {
    margin: 0 auto;
    padding-bottom: 30px;
  }
  .reports-buttons {

    .button {
      width: 155px;
    }
  }

  .reports-head {
    @media (max-width: $big-tablet) {
      display: flex;
    }
    @media (max-width: $mobile) {
      display: block;
    }

    &-left {
      max-width: 70%;
      @media (max-width: $mobile) {
        max-width: 100%;

      }


      &-block {
        span {
          display: inline-block;
        }

        b {
          font-size: 16px;
          padding-left: 5px;
          @media (max-width: $mobile) {
            overflow: auto;
            display: block;
          }
        }
      }
    }
  }

  .reports-table-head {
    margin-bottom: 0;

    &-left {
      .mantine-Tabs-list {
        &:before {
          content: none;
        }

        button {
          border: none;
          color: rgba(0, 0, 0, 0.3);
          font-size: 18px;
          line-height: 22px;
          font-family: $font-bold;

          @media (max-width: $tablet_max) {
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            padding-right: 10px;
          }

        }

        button[data-active="true"] {
          background-color: #EDF0E5;
          color: #000;
        }
      }
    }

    &-right {
      gap: 9px;
      @media (max-width: $mobile) {
        margin-bottom: 15px;
      }

      &-link {
        color: #000;
        font-size: 14px;
        line-height: 17px;
        padding-right: 13px;
        @media (max-width: $tablet_max) {
          padding-right: 3px;
        }
      }

      .mantine-Select-section {
        border-left: 1px solid rgba(112, 112, 112, 0.4);
      }

      .mantine-Select-wrapper input {
        width: 400px;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      [data-expanded="true"] + .mantine-Select-section svg {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
  }

  .reports-head-right {
    max-width: 30%;
    @media (max-width: $mobile) {
      max-width: 100%;
    }


    .reports-blocks {
      width: auto;

      .reports-block {
        padding-left: 9px;
        padding-top: 9px;
        padding-right: 9px;
        padding-bottom: 7px;
        width: 128px;
        height: 70px;
        margin-bottom: 10px;

        b {
          font-size: 16px;
          line-height: 28px;
        }

        &-number {
          font-family: $font-medium;
          font-size: 22px;
          line-height: 28px;
          bottom: 3px;
          position: relative;
          display: block;
          left: 0;
        }
      }
    }
  }

  .html-table table tr th:first-child {
    min-width: 150px;
  }

  .html-table table tr th:nth-child(2) {
    min-width: 300px;
  }

  .html-table table tr th:nth-child(3) {
    min-width: 120px;
  }

  .html-table table tr th:nth-child(4) {
    min-width: 160px;
  }
  .html-table table tr th:nth-child(5) {
    min-width: 140px;
  }

  .html-table-wrap-user-found {
    .html-table table tr th:first-child {
      width: 300px;
    }

    .html-table table tr th:nth-child(2) {
      min-width: 120px;
      text-align: center;
    }

    .html-table table tr td:nth-child(2) {
      text-align: center;
    }

    .html-table table tr th:nth-child(3) {
      min-width: 120px;
      text-align: center;
    }
    .html-table table tr td:nth-child(3),  .html-table table tr td:nth-child(2){
   text-align: center;
    }

    .html-table table tr th:nth-child(4) {
      min-width: 220px;
    }


    .html-table table tr td:first-child, .html-table table tr td:nth-child(2) {
      cursor: pointer;
    }
  }
  .html-table-wrap-stamp-history {
    .html-table table tr td:nth-child(4), .html-table table tr td:last-child {
      cursor: pointer;
    }
  }

  .rb-loading-wrap {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    max-width: none;
    margin-top: 0;
    max-height: none;
    min-height: 0;
    height: 100%;
    top: 0;
    left: 0;
  }

}
