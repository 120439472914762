.learn-more {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 35px);
  cursor: pointer;
  @media (max-width: $mobile-max) {
    bottom: 20px;
  }
  span {
    color: #707070;
    font-size: 14px;
    line-height: 17px;
    display: block;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
  }
}


