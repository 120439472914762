.rb-stamp-found, .rb-stamp-created, .rb-stamp-window {
  flex-grow: 0;
  margin-top: 26px;
  box-shadow: 0 0 20px #00000029;
  max-width: 692px;
  background-color: white;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 59px;
  position: relative;
  @media (min-width: $mobile_min) {
    padding-top: 53px;
  }

  .rb-header {
    @media (min-width: $mobile_min) {
      margin-top: 3px;
      margin-bottom: 0;
    }
  }

  .rb-details-table {
    @media (min-width: $mobile_min) {
      margin-top: 0;
    }
  }

  .rb-btn-verify.rb-btn-verify--another {
    @media (min-width: $mobile_min) {
      margin-top: 33px;
    }
  }

  .scalloped-top, .scalloped-bottom {
    position: absolute;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.14) 40%, #E4E4E4 50%, transparent 0%);
    background-size: 35.8px 30px;
    background-repeat: repeat-x;
    width: 100%;
    height: 31px;
    left: 6px;
    max-width: calc(100% - 16px);
  }

  .scalloped-left, .scalloped-right {
    position: absolute;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.14) 40%, #E4E4E4 50%, transparent 0%);
    background-size: 30px 31.5px;
    background-repeat: repeat-y;
    width: 31px;
    height: 100%;
    top: 10px;
    max-height: calc(100% - 21px);
  }

  .scalloped-top {
    top: -16px;
  }

  .scalloped-bottom {
    bottom: -16px;
  }

  .scalloped-left {
    left: -16px;
  }

  .scalloped-right {
    right: -16px;
  }
}

.rb-information-link {
  color: #1B2F28;
  text-decoration: underline;
  font: normal normal normal 16px/20px $font-light;
  display: block;
  margin-top: 30px;
}

.rb-result-img {
  width: 64px;
  height: 64px;
  margin-top: 102px;
  margin-bottom: 10px;

  @media (min-width: $mobile_min) {
    margin-bottom: 13px;
  }
  @media (max-height: $height_max) {
    margin-top: 28px;
  }
}

.rb-account-info {

  font-size: 14px;
  margin-bottom: 15px;
  @media (min-width: $mobile_min) {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &-key {
    font-family: $font-light;
    padding-bottom: 10px;
    line-height: 20px;
    font-size: 16px;
    color: #707070;

    &__time {
      padding-bottom: 0;
    }
  }

  &-timedelta {
    border-radius: 8px;
    background-color: #EDF0E5;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    display: inline-block;
    margin-top: 10px;
    @media (max-width: $mobile_max) {
      display: none;
    }

  }

  img {
    margin-bottom: 5px;
  }

  &-found__user {
    font-size: 22px;
    line-height: 26px;
    font-family: $font-medium;
    color: #000;
    margin-bottom: 25px;
    word-wrap: break-word;
    max-width: 100%;
    @media (min-width: $mobile_min) {
      margin-right: 35px;
      margin-bottom: 0;
      max-width: 50%;
    }
  }

  &-status {
    font-size: 22px;
    line-height: 26px;
    font-family: $font-medium;
    @media (min-width: $mobile_min) {
      margin-left: 35px;
    }

    img {
      width: 21px;
      height: 21px;
      margin-right: 5px;
    }
  }

  &-found__time {
    font-family: $font-regular;

    position: relative;

    @media (max-width: $mobile_max) {
      white-space: nowrap;
    }
    .help__question.top-right {
      top: -29px;
      right: 30px;
    }
  }
  &-collection {
    font-family: $font-regular;
    position: relative;
    margin-top: 25px;
    margin-bottom: 0;
    padding-right: 60px;

    @media (max-width: $mobile_max) {
      margin-bottom: 10px;
      padding-left: 0;
      padding-right: 0;
    }
    &-wrap {
      @media (min-width: $mobile_min) {
        margin-top: 25px;
      }
    }
  }

  &-wrap {
    @media (max-width: $mobile_max) {
      display: block;
    }
  }
}

.rb-error {
  &-header {
    @media (max-width: $mobile_max) {
      margin-bottom: 36px;
    }

    span {
      display: none;
      @media (min-width: $mobile_min) {
        display: inline-block;
      }
    }
  }

  &-text {
    font-size: 16px;
    line-height: 20px;
    padding-top: 48px;
    padding-bottom: 37px;
    text-align: center;
    word-break: break-all;
    @media (max-width: $mobile_max) {
      padding-top: 0;
      padding-bottom: 87px;
    }

    &-wrap {
      @media (max-width: $mobile_max) {
        background-color: #fff;
        padding: 17px;
        text-align: center;
        width: 100%;
        min-height: 278px;
      }
    }

    &-contact {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 33px;
      text-align: center;
      @media (max-width: $mobile_max) {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &-timestamp {
    font-size: 12px;
    position: absolute;
    bottom: 23px;
    font-family: $font-light;
  }
}
