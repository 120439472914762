.landing__three {
  background-color: #EDF0E5;
  padding-top: 80px;
  padding-bottom: 100px;
  @media (max-width: $tablet_max) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &-title {
    font-size: 36px;
    line-height: 50px;
    font-family: $font-regular-secondary;
    max-width: 846px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 19px;
  }


  &-text {
    font-size: 18px;
    line-height: 26px;
    font-family: $font-light;
    max-width: 846px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  &-blocks {
    @media (max-width: $mobile_max) {
      display: block;
    }
    &-item {
      background-color: #fff;
      padding-top: 50px;
      padding-left: 28px;
      padding-right: 24px;
      padding-bottom: 100px;
      width: calc(33.33333% - 22px);
      @media (max-width: $tablet_max) {

        padding-bottom: 50px;
      }
      @media (max-width: $mobile_max) {
        width: 100%;
        margin-bottom: 30px;
      }
      &-content {
        height: 100%;

        img {
          margin-bottom: 18px;
        }

        span {
          display: block;
          font-size: 20px;
          line-height: 24px;
          font-family: $font-bold;
          color: #000000;
          margin-bottom: 20px;
        }

        p {
          color: #000000;
          font-size: 16px;
          line-height: 24px;
          font-family: $font-regular;
        }
      }
    }
  }


}

.supplementary-information {
  &__title {

    max-width: 100%;
    margin-bottom: 35px;
  }

  &__blocks {
    &-item {
      padding-bottom: 38px;
      padding-left: 25px;
      padding-right: 22px;
    }
  }
}
