.quota {
  &-limit {
    position: relative;
    width: 100%;
    font-size: 16px;
    padding: 20px 15px;
    color: #000;
    @media (min-width: $mobile_min) {
      font-size: 22px;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &-close {
      position: absolute;
      right: -30px;
      top: -30px;
      cursor: pointer;
    }

    &-header {

      padding-right: 30px;

      button {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }


      }


      &-menu {
        position: relative;
        background-color: #f9f9f9;
        list-style-type: none;
        padding: 20px;
        cursor: default;
        z-index: 10;
        box-shadow: 0 7px 8px rgba(0, 0, 0, 0.16);
        margin: 22px;

        &-wrap {
          overflow: hidden;
          position: absolute;
          z-index: 1;
          margin-left: -70px;
          height: 0;
          transition: height 0.5s ease-in-out;
        }

        &-wrap.active {
          height: 320px;
        }

        &-item {
          margin-bottom: 5px;

          &-title {
            font-size: 16px;
            font-family: $font-bold;
          }

          &-divider {
            border-bottom: 1px solid;
            margin-bottom: 10px;
            margin-top: 10px;

          }

          b {
            font-family: $font-bold;
          }
        }
      }
    }

    &-wrap {
      width: 100%;
      position: fixed;
      background-color: #fff;
      z-index: 3;
      max-width: 300px;
      margin-top: 5rem;
      outline: 1px solid #D0D0D0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      top: 5rem;

      left: calc(50% - 150px);
      @media (min-width: $mobile_min) {
        left: calc(50% - 195px);
        max-width: 390px;
      }
    }

    &__title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      @media (min-width: $mobile_min) {
        font-size: 22px;
      }
    }

    &__text {
      width: 100%;
      text-align: center;
      font-size: 14px;
      margin-top: 10px;
      font-family: $font-light;
      @media (min-width: $mobile_min) {
        font-size: 16px;
      }
    }

    &-bg {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: 2;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }

}

.quota-used-100 {
  .rb-notification-buttons-item {
    display: inline-block;
  }
}
