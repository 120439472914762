.pricing-page {
  padding-top: 100px;

  .rb-container {
    max-width: 1148px;
  }

  &-header {
    margin-bottom: 57px;

    img {
      max-width: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  &-periods {

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    &-item {
      border: 1px solid #fff;
      width: 107px;
    }

    &-active {
      background-color: #1B2F28;

      label {
        color: #fff;
      }
    }

    &-month {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-right: none;
    }

    &-year {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-left: none;
    }
  }

  &-plans {

    margin-left: auto;
    margin-right: auto;

    &-item {

      &-buttons {
        margin-top: 20px !important;
      }

      &-first {
        background-color: #EDF0E5;
      }

      &-first {
        .pricing-block-item-body-item svg {
          fill: #1B2F28;
        }
      }

      &-third {
        @media (max-width: $tablet_max) {
          flex-basis: calc(50% - 5px);
        }

        .pricing-page-block-item-head-current {
          background-color: #fff;
        }

        .pricing-page-block-item-footer {

          svg {
            fill: #1B2F28;

            path {
              stroke: #fff;
            }

          }
        }
      }
    }

    &-custom {
      width: 100%;
      overflow: hidden;
      padding-left: 8.9%;
      margin-top: 50px;
      border: 1px solid #C6E870;
      background-color: #FFFFFF;
      position: relative;
      padding-bottom: 54px;
      @media (max-width: $tablet_max) {
        padding-left: 30px;
        padding-bottom: 10px;
      }
      @media (max-width: $mobile) {
        display: block;
        padding-bottom: 280px;
      }

      &-item {
        &-first {
          width: 31%;
          @media (max-width: $tablet_max) {
            padding-right: 10px;
          }
          @media (max-width: $mobile) {
            width: 230px;
          }
        }

        &-title {
          font-size: 42px;
          line-height: 48px;
          font-family: $font-bold;
          padding-top: 65px;
          margin-bottom: 35px;
          @media (max-width: $tablet_max) {
            padding-top: 25px;
            font-size: 36px;
            line-height: 40px;
          }
        }

        &-buttons {
          @media (max-width: $mobile) {
            display: none;
          }

          .button {
            background-color: #1B3028;
            color: #FFFFFF;
            font-size: 18px;
            padding-bottom: 12px;
            padding-top: 12px;
            padding-left: 48px;
            padding-right: 48px;
            border: 1px solid #1b3028;

            &:hover {
              background-color: #fff;
              color: #1B3028;
            }
          }
        }

        &-second {
          padding-top: 72px;
          position: relative;
          z-index: 1;
          max-width: 31%;
          @media (max-width: $tablet_max) {
            padding-top: 32px;
          }
          @media (max-width: $mobile) {
            width: 230px;
            padding-top: 0;
            max-width: none;
          }

        }

        &-line {
          @media (max-width: $mobile) {
            display: none;
          }
          padding-bottom: 18px;

          svg {
            margin-right: 13px;
            position: relative;
            top: 5px;
            fill: #1B2F28;
            height: 10px;
          }

          &-text {
            font-family: $font-light;
            font-size: 18px;
            line-height: 21px;
            @media (max-width: $tablet_max) {
              font-size: 16px;
              line-height: 19px;
            }
            b {
              font-family: $font-bold;
              font-weight: normal;
            }
          }
        }

        &-img {
          position: absolute;
          right: -57px;
          @media (max-width: $tablet_max) {
            max-width: 360px;
            bottom: -113px;
          }
          @media (max-width: $mobile) {
            bottom: -71px;
            right: -70px;
          }
        }

        &-mobile {
          display: none;
          @media (max-width: $mobile) {
            display: block;
          }

          span {
            display: block;
            font-family: $font-light;
          }

          a {
            text-decoration: underline;
            font-size: 18px;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }


    }
  }

  &-block {
    &-item {
      @media (max-width: $small-laptop) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &-head {
        margin-bottom: 19px;

        &-subtitle {
          font-size: 40px;
          font-family: $font-bold;

          span {
            font-family: $font-light;
          }

          &-year {
            padding-top: 20px;
            width: 100%;
            font-size: 20px;

            @media (max-width: $small-laptop) {
              font-size: 17px;
              line-height: 30px;
            }
          }
        }

        &-wrap {
          width: 100%;
          padding-top: 18px;
          min-height: 91px;
        }

        &-current {
          background-color: #C6E870;
          width: auto;
          color: #000;
          font-size: 14px;
          border-radius: 8px;
          padding-top: 6px;
          padding-bottom: 5px;
          padding-left: 11px;
          padding-right: 11px;
          min-width: 101px;
          font-family: $font-bold;
        }
      }

      &-footer {
        svg {
          fill: #c6e870;

          margin-top: 5px;
          top: 5px;
          position: relative;
          path {
            stroke: #1b2f28;
          }
        }
      }

    }
  }

  .back-free-window {

      width: 300px;
      height: 200px;
      position: fixed;
      display: flex;
      background-color: #fff;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      z-index: 3;
      margin-top: 5rem;
    outline: 1px solid #D0D0D0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    &__title {
      width: 100%;
      text-align: center;
      font-size: 22px;
    }
    &__buttons {
      button {
        font-family: $font-bold;
        font-size: 20px;
        margin: 10px;
        cursor: pointer;
        margin-top: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &-diy {
    padding-top: 13px;
    padding-bottom: 14px;
    padding-left: 18px;
    padding-right: 20px;
    border: 1px solid #D4D6CD;
    font-family: $font-light;
    font-size: 21px;
    text-align: center;
    margin-bottom: 26px;
    background-color: rgba(237, 240, 229, 0.6);

    img {
      width: 28px;
      height: 28px;
      padding-right: 10px;
    }
    span {
      font-family: $font-bold;
      padding-right: 10px;
    }
    p {
      margin: 0;
      padding-right: 10px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
