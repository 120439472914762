.rb-loading {
&__loader {
  position: relative;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  span {
    display: block;
    width: 5rem;
    height: 5rem;
    border: 0.75rem solid #e9e9e9;
    border-top: 0.75rem solid #000;
    border-radius: 50%;
    position: absolute;
  }
}
  &__title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    @media (min-width: $mobile_min) {
      font-size: 22px;
    }
  }
  &__text {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    font-family: $font-light;
    @media (min-width: $mobile_min) {
      font-size:15px;
    }
  }
  &-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  &-wrap {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    position: fixed;
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    max-width: 700px;
    margin-top: 5rem;
    max-height: 520px;
    min-height: 200px;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      @media (min-width: $mobile_min) {
          content: none;
        }
    }
  }
}
