.landing {

  &__container {
    display: block;
    position: relative;
    padding: 20px 20px 12px 20px;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1148px;
  }
}
.landing.active {
  height: 100%;
}
