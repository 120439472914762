.guaranteed {
  text-align: center;
  padding-top: 63px;
  @media (max-width: $tablet_max) {
    padding-top: 13px;
  }

  &__title {
    font-size: 26px;
    line-height: 50px;
    font-family: $font-bold;
    @media (max-width: $mobile_max) {
      font-size: 23px;
    }
  }

  &__text {
    max-width: 846px;
    font-family: $font-light;
    font-size: 18px;
    line-height: 26px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  &__blocks {
    padding-top: 50px;
    @media (max-width: $tablet_max) {
      padding-top: 10px;
    }

    &-item {
      margin-bottom: 100px;
      @media (max-width: $tablet_max) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
      }

      &-content {
        min-width: 542px;
        max-width: 636px;
        background-color: #F7F7F7;
        overflow: hidden;
        position: relative;
        width: 100%;
        min-height: 397px;
        padding-top: 83px;
        padding-left: 70px;
        padding-right: 68px;
        padding-bottom: 30px;
        @media (max-width: $tablet_max) {
          max-width: 100%;
        }
        @media (max-width: $mobile_max) {
          min-width: auto;
          padding-top: 40px;
          padding-left: 35px;
          padding-right: 35px;
        }

        .border-top, .border-bottom {
          background-color: rgba(112, 112, 112, 0.2);
          width: 100%;
          height: 1px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .border-left, .border-right {
          background-color: rgba(112, 112, 112, 0.2);
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .border-bottom {
          bottom: 0;
          top: auto;
        }

        .border-right {
          left: auto;
          right: 0;
        }


        .scalloped-top, .scalloped-bottom {
          position: absolute;
          background: radial-gradient(ellipse at center, #fff 42%, rgba(112, 112, 112, 0.2) 50%, transparent 0%);
          background-size: 35.8px 30px;
          background-repeat: repeat-x;
          width: 100%;
          height: 31px;
          left: 12px;
          max-width: calc(100% - 22px);
        }

        .scalloped-left, .scalloped-right {
          position: absolute;
          background: radial-gradient(ellipse at center, #fff 42%, rgba(112, 112, 112, 0.2) 50%, transparent 0%);
          background-size: 30px 31.5px;
          background-repeat: repeat-y;
          width: 31px;
          height: 100%;
          top: 12px;
          max-height: calc(100% - 16px);
        }

        .scalloped-top {
          top: -16px;
        }

        .scalloped-bottom {
          bottom: -16px;
        }

        .scalloped-left {
          left: -16px;
        }

        .scalloped-right {
          right: -16px;
        }

      }

      &-texts {
        max-width: 430px;
        padding-right: 20px;
        text-align: left;
        color: #000;
        @media (max-width: $tablet_max) {
          max-width: 100%;
          padding-right: 0;
        }

        &-title {
          font-family: $font-regular-secondary;
          font-size: 36px;
          line-height: 42px;
          max-width: 390px;
          @media (max-width: $tablet_max) {
            max-width: 100%;

          }
        }

        &-text {
          font-size: 18px;
          line-height: 26px;
          font-family: $font-light;
          margin-top: 28px;
          @media (max-width: $tablet_max) {
            padding-bottom: 30px;
          }

          b {
            font-family: $font-bold;
            font-weight: normal;
          }
        }
      }

      &-secure {

        .guaranteed__blocks-item-content {
          padding: 35px;
        }

        .guaranteed__blocks-item-texts {
          max-width: 452px;
          @media (max-width: $tablet_max) {
            max-width: 100%;
          }
        }
      }
    }

    &-text-right {
      .guaranteed__blocks-item-texts {
        padding-right: 0;
        padding-left: 20px;
        @media (max-width: $tablet_max) {
          padding-left: 0;
          order: -1;
        }

      }

      .guaranteed__blocks-item-content {
        padding: 35px;
      }
    }
  }

  &__fingerprint {
    &-top {
      @media (max-width: $mobile_max) {
        display: block;
      }

      &-browse {
        position: relative;
        @media (min-width: 1120px) {
          margin-left: -40px;
        }
        @media (max-width: $mobile_max) {
          max-width: 161px;
          margin-left: auto;
          margin-right: auto;
        }

        span {
          position: absolute;
          color: #fff;
          font-size: 13px;
          line-height: 16px;
          left: 53px;
          top: 18px;
          font-family: $font-bold;
        }
      }

      &-arrow {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 10px;
        @media (max-width: $mobile_max) {
          margin-top: 55px;
          transform: rotate(90deg);
          margin-bottom: 55px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          top: -57px;
          position: relative;
        }
        &-wrap {
          @media (max-width: $mobile_max) {
            overflow: hidden;
            max-height: 90px;
            margin-top: 10px;
          }
        }
      }

      &-fingerprint {
        @media (max-width: $mobile_max) {
          margin-left: -20px;
        }
      }


    }

    &-bottom {
      margin-top: 36px;
      @media (max-width: $mobile_max) {
        display: block;
      }

      &-item {
        max-width: 218px;
        color: #000;
        font-size: 14px;
        line-height: 17px;
        @media (max-width: $mobile_max) {
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto;
        }

        span {
          margin-bottom: 13px;
          display: block;
          font-family: $font-bold;
        }

        p {

          font-family: $font-light;
        }
      }
    }
  }

  &__stamp {
    background-color: #fff;
    padding-top: 14px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
    @media (max-width: $mobile_max) {
      padding: 0;
      background: none;
    }

    .rb-result-img {
      margin-top: 28px;
    }
  }
}

