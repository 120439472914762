.user-profile {
  background-color: #fff;
  padding-bottom: 0;

  &-header {
    padding-top: 100px;
    padding-bottom: 30px;
  }

  &-tabs {
    &-buttons {
      width: 100%;
      border-bottom: 4px solid #E0E0E0;

      &-block {


        &-wrap {
          position: relative;
          bottom: -4px;
          border-bottom: 4px solid #fff;
          @media (max-width: $mobile_max) {

            bottom: 0;
          }
          button {
            cursor: pointer;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 22px;
            padding-bottom: 10px;
            border-bottom: 4px solid #E0E0E0;
            padding-left: 25px;
            padding-right: 25px;
            position: relative;
            margin-left: 1px;
            margin-right: 1px;
            bottom: -4px;
            font-family: $font-medium;

            @media (max-width: $mobile_max) {
              width: calc(50% - 2px);
              margin-bottom: 15px;
              bottom: 0;
            }
          }

          button.active {
            color: #000;
            border-color: #000;
            cursor: default;
          }
        }
      }
    }

    &-content {
      width: 100%;
      background-color: #EDEDED;
      height: 100%;
      &-item {
        padding-top: 83px;
        padding-bottom: 100px;
        @media (max-width: $mobile_max) {
          padding-top: 40px;
          padding-bottom: 50px;
        }

      }
    }
  }

  &-line {
    gap: 20px;
    margin-bottom: 32px;
    @media (max-width: $mobile_max) {
      flex-wrap: wrap;
      gap: 0;
    }
    label {
      font-size: 18px;
      font-family: $font-bold;
      width: 236px;
      text-align: right;
      @media (max-width: $tablet-max) {
        width: 160px;
      }
      @media (max-width: $mobile_max) {
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
      }
    }

    .rb-input {
      font-size: 18px;
      border-radius: 2px;

      background-color: #f8f9fa;
      font-family: $font-regular;
      width: 350px;
      height: 42px;
      @media (max-width: $tablet-max) {
        max-width: 250px;
      }
      @media (max-width: $mobile_max) {
        max-width: 100%;
        width: 100%;
      }

      &-wrap {
        position: relative;
        margin-bottom: 0;
        @media (max-width: $mobile_max) {
          width: 100%;
        }

        input {

          @media (max-width: $mobile_max) {
            width: 100%;
          }
        }

        .rb-input-error {
          border-color: #C42905;

        }
        .rb-input-text-error {
          color: #C42905;
          position: absolute;
          line-height: 14px;
          font-size: 14px;
          font-family: $font-light;
        }
        .btn-show-hide {
          top: 1px;
          width: auto;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          i {
            @media (max-width: $mobile_max) {
              top: -2px;
            }
          }
        }

      }

    }

    .disabled {
      border-color: rgba(112, 121, 112, 0.2);
      background-color: rgba(247, 247, 247, 0.8);
    }

    button, &-link a {
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
      width: 65px;
      white-space: nowrap;
      @media (max-width: $tablet-max) {
        white-space: normal;
        width: 100px;
      }
      @media (max-width: $mobile_max) {
        width: 100%;
        text-align: left;
        margin-top: 3px;
      }
      span {
        overflow: initial;
      }

      &:hover {
        text-decoration: none;
      }
    }
    &-billing-history button {
      width: 100%;
      text-align: left;
    }

    .no-active {
      z-index: -1;
    }

    &-date {
      margin-top: 60px;
      @media (max-width: $mobile_max) {
        margin-top: 30px;
      }
      span {
        width: 350px;
        @media (max-width: $tablet-max) {
          width: 250px;
        }
        @media (max-width: $mobile_max) {
          width: 100%;
        }
      }
    }

    &-password, &-link {
      margin-top: 60px;
      @media (max-width: $mobile_max) {
        margin-top: 30px;
      }
      button, a {
        width: 434px;
        text-align: left;
        @media (max-width: $tablet-max) {
          width: 337px;
        }
        @media (max-width: $mobile_max) {
          width: 100%;
        }
      }
    }

    &-cancel {
      margin-top: 60px;
      @media (max-width: $mobile_max) {
        margin-top: 30px;
      }
      button {
        width: 434px;
        text-align: left;
        color: #FF0000;
        @media (max-width: $tablet-max) {
          width: 337px;
        }
        @media (max-width: $mobile_max) {
          width: 100%;
        }
      }
      &-choice {
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -24px;
        left: -33px;
        position: relative;
      }
      &-message {
        margin-top: -20px;
        position: relative;
        left: -20px;
      }
    }

  }
  .button-dark {
    margin-top: 20px;
    background-color: #000;
    color: #fff;
    border: 1px solid transparent;

    svg {
      fill: #fff;
      transform: rotate(180deg);
      margin-right: 10px;

    }
    &:hover {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
      &:hover {
        svg {
          fill: #000;
        }
      }
    }
  }
}
