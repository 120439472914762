.rb-notification {
  &-block {
    background-color: #EDF0E5;
    color: #000;
    border: 1px solid rgba(112, 112, 112, 0.5019607843);
    text-align: center;
    padding: 10px 20px;
    position: absolute;
    top: -20px;
    width: 80%;
    left: 10%;
    border-radius: 4px;
    -webkit-box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);
    -moz-box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);
    box-shadow: 3px 10px 20px -6px rgb(94, 103, 98);

  }

  &-text {
    p {
      font-size: 15px;
      padding-top: 5px;
      @media (min-width: $mobile_min) {
        font-size: 19px;
      }
    }
  }


  &-buttons {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    max-width: 250px;
    margin-bottom: 5px;

    &-item {

      padding-left: 25px;
      padding-right: 25px;
      padding-top: 5px;
      padding-bottom: 5px;
      @media (min-width: $mobile_min) {
        padding-left: 30px;
        padding-right: 30px;

      }
    }
  }

}

//Corrected for Stamp Content ID
.rb-hash-input {
  .rb-notification-block {
    &:before, &:after {
      top: 3px;
    }
  }
}
