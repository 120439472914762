.pagination-controls {
  &-item {
    padding: 2px 15px 0px 15px;
    font-size: 14px;
    line-height: 28px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    border-right: none;
    cursor: pointer;
    @media (max-width: $mobile) {
      padding: 0 8px 0 8px;
    }
    &:first-child {
      @media (max-width: $mobile) {
        display: none;
      }
    }
    &:last-child {
      border-right: 1px solid rgba(112, 112, 112, 0.2);
      @media (max-width: $mobile) {
        display: none;
      }
    }
    &:nth-last-child(2) {

      @media (max-width: $mobile) {
        border-right: 1px solid rgba(112, 112, 112, 0.2);
      }
    }
  }

  &-disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.3);
  }

  &-active {
    cursor: default;
    background-color: #EDF0E5;
  }

}
