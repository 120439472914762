.transform-data-security {
  padding-top: 66px;
  padding-bottom: 70px;
  @media (max-width: $tablet_max) {
    padding-top: 33px;
    padding-bottom: 35px;
  }
  &__blocks {
    background-color: rgba(237,237,237,0.4);
    border: 1px solid #C6E870;
    border-radius: 2px;
    @media (max-width: $mobile-max) {
      display: block;
    }
    &-texts {
      padding-left: 88px;
      padding-top: 38px;
      padding-right: 20px;
      padding-bottom: 47px;
      max-width: 603px;
      @media (max-width: $mobile-max) {
        padding-left: 20px;
      }
      span {
        display: block;
        font-size: 36px;
        line-height: 42px;
        font-family: $font-bold;
        margin-bottom: 21px;
        color: #000;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        font-family: $font-light;
        margin-bottom: 6px;
        color: #000;
      }
    }
    &-img {
      min-width: 280px;
    }
  }
}
