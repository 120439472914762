.rb-top-menu {
  display: flex;
  gap: 7px;
  align-items: center;
  position: relative;
  padding: 20px 0 12px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1148px;

  &-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.rb-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 18px;
    height: 23px;

    @media (min-width: $mobile_min) {
      width: 26px;
      height: 33px;
      margin-left: 0;
      margin-right: 26px;
    }
  }

  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 1.25rem;
  }
}

.rb-menu-desktop {
  display: none;

  @media (min-width: $mobile_min) {
    display: flex;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    width: calc(100% - 55px);
    justify-content: space-between;
  }

  .links {
    display: flex;
    gap: 26px;
    align-items: center;

    a {
      color: black;
      cursor: pointer;
    }
  }
}

.rb-user-card {
  display: flex;
  font-size: 14px;
  gap: 4px;
  cursor: pointer;
  height: 40px;
  align-items: center;

  &:hover {


    .arrow {
      color: #535856;
    }

    .rb-user-data {
      opacity: 0.6;
    }
  }
  p {
    @media (max-width: $mobile_max) {
      max-width: 100px;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    @media (min-width: $mobile_min) {
      width: 36px;
      height: 36px;
    }

  }

  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    .nav-item {
      position: relative;

      &-question {
        a {
          margin-left: -9px !important;

          svg {
            fill: #88938f;
          }
        }

        .dropbtn {

          padding-right: 11px;
        }

        &:hover {
          svg {
            fill: #A7DD1E;
          }
        }
      }

      a {
        display: block;
        color: black;
        text-align: center;
        padding: 13px 0 15px 0;
        text-decoration: none;
        margin-left: 10px;

      }

    }
  }

  span {

    font-family: $font-bold;
  }
}

.rb-user-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;

  .name {
  }

  .email {
    color: #707070;
  }
}

.dropdown-content {

  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
  margin-top: 289px;
  width: 245px;
  right: 50px;

  @media (min-width: $mobile_min) {
    margin-top: 235px;
    right: 0;
  }

  a {
    font: normal normal normal 16px/20px $font-regular;
    color: black;
    padding: 18px 20px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .logout-btn {
    opacity: 0.5;
    border-top: 1px solid #70707033;
  }
}

.rb-menu-mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: $mobile_min) {
    display: none;
  }
}

.dropdown-content-mobile {

  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
  margin-top: 255px;
  width: 160px;
  right: 0;

  a {
    font: normal normal normal 16px/20px $font-regular;
    color: black;
    padding: 18px 20px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  //TODO: remove
  .logout-btn {
    opacity: 0.5;
    border-top: 1px solid #70707033;
  }
}


//TODO: remove
.arrow {
  margin-left: 5px;
  color: #88938F;
}

.arrow-mobile {
  margin-left: 5px;
  color: #88938F;
}

.rb-menu-mobile__toggle {
  cursor: pointer;
}

.rb-menu-mobile__toggle.open ~ .rb-menu-mobile__items {
  max-height: 30vh;
}

.links.open .rb-menu-desktop__items {
  max-height: 32.5vh;
}

.rb-menu-mobile__items, .rb-menu-desktop__items {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 252px;
  left: 0;
  top: 73px;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition: max-height 300ms ease-out;
  font-size: 1rem;
  font-weight: 500;
  gap: 1.5rem;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 100;
  box-shadow: 0px 16px 16px -16px #0000001a;

  > *:first-child {
    margin-top: 1.5rem;
  }

  > *:last-child {
    margin-bottom: 2rem;
  }
}

.rb-not-authentication-buttons {
  a {
    font-size: 14px;
    line-height: 17px;
    padding: 12px 29px 10px;
    font-family: $font-medium;

    @media (max-width: $mobile_max) {
      padding: 4px 12px 4px;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .rb-btn-black {
    margin-right: 10px;
    @media (max-width: $mobile_max) {
      margin-right: 5px;
    }

    &:hover {
      border-color: rgba(112, 112, 112, 0.2);
    }
  }

  .rb-btn-white {
    border-color: rgba(112, 112, 112, 0.2);
  }
}
