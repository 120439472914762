.html-table {
  min-width: 815px;
  margin-right: 10px;
  margin-left: 10px;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tr {
      th {
        padding-bottom: 7px;
        background-color: #EDF0E5;
        padding-top: 9px;
        font-family: $font-bold;

        &:first-child {
          padding-left: 15px;
        }
      }

      td {
        padding-top: 11px;
        padding-bottom: 10px;

        &:first-child {
          padding-left: 15px;
        }

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }

        }

        button {
          cursor: pointer;
        }
      }

      &:nth-child(even) {
        td {
          background-color: rgba(237, 240, 229, 0.4);
          border-bottom: 1px solid rgba(112, 112, 112, 0.3);
          border-top: 1px solid rgba(112, 112, 112, 0.3);
        }
      }
    }
  }

  &-wrap {
    overflow-y: auto;
    margin-bottom: 28px;

    .no-data {
      font-size: 16px;
      display: flex;
      width: 100%;
      justify-content: center;
      height: 130px;
      align-items: flex-end;
      font-family: $font-light;
    }
  }

  &-copy {
    cursor: pointer;
    margin-left: 5px;
  }

  &-custom {
    min-width: auto;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0.875em;

    &-wrap {
      > div {
        overflow-y: hidden;
      }

      table tr th:last-child .m_8bffd616 {

        justify-content: center;
      }

      &-tolerance {
       table {

       tr {


       th:last-child {

       .m_8bffd616 {

          justify-content: flex-start;
        }
       }

         &:nth-child(even) {
           background-color: #F9FAF7;
         }
       }
       }

      }
    }

    .mantine-ActionIcon-root {
      background: none;
      top: -2px;

      &:hover {
        background: none;
      }

      svg {
        stroke: rgba(112, 112, 112, 0.4);

      }
    }
&-tolerance-new {
  tr {
    th {
      .mrt-table-head-cell-content {
        justify-content: center !important;
      }

    }
  }
}
  }
}



