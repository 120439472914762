.pricing {
  padding-bottom: 88px;
  padding-top: 61px;
  @media (max-width: $tablet_max) {
    padding-bottom: 44px;
    padding-top: 30px;
  }
  &__title {
    text-align: center;
    padding-bottom: 15px;
    font-size: 36px;
    line-height: 42px;
    font-family: $font-regular-secondary;
  }

  &__text {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    font-family: $font-light;
    padding-bottom: 60px;
    color: #333333;
  }

  &-block {
    @media (max-width: $tablet_max) {
      justify-content: center;
    }
    @media (max-width: $mobile_max) {
      display: block;

    }

    &-item {
      flex-direction: column;
      width: calc(33.33333% - 20px);
      padding-left: 38px;
      padding-right: 43px;
      padding-top: 33px;
      padding-bottom: 57px;
      @media (max-width: $tablet_max) {
        width: calc(50% - 18px);
      }
      @media (max-width: $mobile-max) {
        margin-left: auto;
        margin-right: auto !important;
        width: 100%;
        max-width: 366px;
        padding: 30px;

      }

      &-first {
        border: 1px solid rgba(112, 112, 112, 0.2);
        @media (max-width: $tablet_max) {
          margin-right: 31px;
        }
        @media (max-width: $mobile-max) {
          margin-right: 31px;
        }
      }

      &-second {
        background-color: #1B2F28;
        color: #fff;
        @media (max-width: $mobile-max) {
          margin-top: 31px;
        }

        .pricing-block-buttons {
          .button {
            &:hover {
              background-color: #C6E870;
              color: #000;
            }
          }

        }


      }

      &-third {
        background-color: #C6E870;
        @media (max-width: $tablet_max) {
          flex-basis: calc(33.33333% - -50px);
          margin-top: 31px;
        }
        @media (max-width: $mobile-max) {
          flex-basis: calc(33.33333% - -50px);
          margin-top: 31px;
        }

        .pricing-block-item-body-item svg {
          fill: #1B2F28;
        }
      }

      &-head {
        margin-bottom: 19px;
        min-height: 64px;
        @media (max-width: $mobile-max) {
          margin-bottom: 5px;
        }

        &-title {
          font-family: $font-bold;
          font-size: 30px;
          line-height: 37px;
          text-transform: uppercase;
        }

        &-subtitle {
          font-family: $font-light;
          line-height: 23px;
          font-size: 16px;
          padding-top: 4px;
        }
      }

      &-body {
        border-top: 1px solid rgba(112, 112, 112, 0.2);
        padding-top: 33px;
        @media (max-width: $mobile-max) {
          padding-top: 10px;
        }

        &-item {
          padding-bottom: 20px;

          svg {
            margin-right: 13px;
            position: relative;
            top: 5px;
            fill: #E6E6E6;
            height: 10px;
          }

          &-text {
            line-height: 22px;

            span {
              display: block;
            }
          }
        }
      }

      &-footer {
        margin-top: auto;

        .pricing-block-buttons {
          margin-top: 50px;
          @media (max-width: $mobile-max) {
            margin-top: 20px;
          }

          .button {
            border-radius: 4px;
            line-height: 20px;
            padding: 12px 42px 10px 42px;
            text-decoration: none;
            font-size: 18px;
          }
          .button-white {
            background-color: #fff;
            border: 1px solid #1b3028;
            color: #000;
            &:hover {
              background-color: #1b3028;
              color: #fff;
            }
          }
          .button-dark {
            background-color: #1b3028;
            border: 1px solid #1b3028;
            color: #fff;
            &:hover {
              background-color: #fff;
              color: #1b3028;
            }
          }

        }
      }
    }
  }
  &-block-item-second .pricing-block-buttons .button-white:hover {
    background-color: #c6e870;
    color: #000;
  }
}
